import React, { Component } from 'react';
import './CustomInput.css'

class CustomInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false
        };
    }

    onFocus = () => {
        this.setState({ focus: true });
    }

    onBlur = () => {
        this.setState({ focus: false });
    }
    render() {
        const { error } = this.props;
        const { focus } = this.state;
        return (
            <div className="Custom_input">
                <div className="input-container">
                    <input className="Input-form"
                        onKeyDown={this.props.onKeyDown}
                        type={this.props.type}
                        name="input"
                        autoFocus={this.props.autoFocus}
                        onChange={this.props.onChange}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        defaultValue={this.props.defaultValue && this.props.defaultValue}
                        value={this.props.value}
                        min={this.props.min}
                        onFocus={this.onFocus} onBlur={this.onBlur}
                        required
                    />
                    <label type={this.props.type}  className="input-label" id="input-label" htmlFor={this.props.id} >{this.props.label}</label>
                </div>
                {
                    error && !focus && (<div>
                        <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="#FF068B" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                        </svg>
                        <div className="error_text">{this.props.errorMessage ? this.props.errorMessage : "Ce champs est obligatoire"}</div>
                    </div>)
                }
            </div>
        );
    }
}

export default CustomInput;