import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from './Pages/Dashboard/Dashboard';
import Ordonnance from './Pages/Ordonnance/Ordonnance';
import Repports from './Pages/Repports/Repports';
import Account from './Pages/Account/Account';
import AccountLogins from './Pages/AccountLogins/AccountLogins';
import SuccessComponent from './Helpers/AuthComponent/SuccessComponent';
import failureComponent from './Helpers/AuthComponent/failureComponent';
import store from './Store/configStore'
import OrdonnancesTable from './Pages/OrdonnancesTable/OrdonnancesTable';
import generalInfo from './Pages/generalInfo/generalInfo';
import generalInfoEdit from './Pages/generalInfoEdit/generalInfoEdit';
import PharmacyService from './Pages/PharmacyService/PharmacyService';
import PharmacyServiceEdit from './Pages/PharmacyServiceEdit/PharmacyServiceEdit';
import PharmacyPayment from './Pages/PharmacyPayment/PharmacyPayment';
import PharmacyLanguages from './Pages/PharmacyLanguages/PharmacyLanguages';
import PharmacyLanguagesEdit from './Pages/PharmacyLanguagesEdit/PharmacyLanguagesEdit';
import PharmacyPaymentEdit from './Pages/PharmacyPaymentEdit/PharmacyPaymentEdit';
import PharmacyTimetable from './Pages/PharmacyTimetable/PharmacyTimetable';
import PharmacyTimetableEdit from './Pages/PharmacyTimetableEdit/PharmacyTimetableEdit';
import DisplayParams from './Pages/DisplayParams/DisplayParams';
import DisplayParamsEdit from './Pages/DisplayParamsEdit/DisplayParamsEdit';
import PharmacyImages from './Pages/PharmacyImages/PharmacyImages';
import ChoiceCountries from './Pages/ChoiceCountries/ChoiceCountries';
import ChoiceCountriesGetData from './Pages/ChoiceCountriesGetData/ChoiceCountriesGetData';
import LandingPage from './Pages/LandingPage/LandingPage';
import ConditionsPage from './Pages/LandingPage/ConditionsPage';
import PolitiquesPage from './Pages/LandingPage/PolitiquesPage';
import NotFoundPage from './Pages/404Page/404Page';
import generalInfoPharmasist from './Pages/generalInfoPharmasist/generalInfoPharmasist';
import generalInfoPharmasistEdit from './Pages/generalInfoPharmasistEdit/generalInfoPharmasistEdit';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    return (
      <Provider store={store} >
        <BrowserRouter>
          <div className="App" >
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path='/error' exact component={NotFoundPage} />
              <Route path="/home" exact component={Dashboard} />
              <Route path="/ordonnances" exact component={OrdonnancesTable} />
              <Route path="/ordonnances/view/:id" exact component={Ordonnance} />

              <Route path="/reports" exact component={Repports} />
              <Route path="/account" exact component={Account} />
              <Route path="/account/useraccountlogins" exact component={AccountLogins} />

              <Route path="/account/generalinfo" exact component={generalInfo} />
              <Route path="/account/generalinfoedit" exact component={generalInfoEdit} />

              <Route path="/account/generalinfo_pharmasist" exact component={generalInfoPharmasist} />
              <Route path="/account/generalinfo_pharmasistedit" exact component={generalInfoPharmasistEdit} />
              
              <Route path="/account/pharmacy_service" exact component={PharmacyService} />
              <Route path="/account/pharmacy_service_edit" exact component={PharmacyServiceEdit} />

              <Route path="/account/pharmacy_payment" exact component={PharmacyPayment} />
              <Route path="/account/pharmacy_payment_edit" exact component={PharmacyPaymentEdit} />

              <Route path="/account/pharmacy_languages" exact component={PharmacyLanguages} />
              <Route path="/account/pharmacy_languages_edit" exact component={PharmacyLanguagesEdit} />

              <Route path="/account/pharmacy_timetable" exact component={PharmacyTimetable} />
              <Route path="/account/pharmacy_timetable_edit" exact component={PharmacyTimetableEdit} />

              <Route path="/account/pharmacy_images" exact component={PharmacyImages} />

              <Route path="/account/display_params" exact component={DisplayParams} />
              <Route path="/account/display_params_edit" exact component={DisplayParamsEdit} />

              <Route path='/auth/switch_accounts' exact component={ChoiceCountries} />
              <Route path='/choose-pharmacy' exact component={ChoiceCountriesGetData} />

              <Route path="/conditions" component={ConditionsPage} />
              <Route path="/politiques" component={PolitiquesPage} />

              <Route path='/auth/success' exact component={SuccessComponent} />
              <Route path='/auth/failed' exact component={failureComponent} />
            </Switch>
          </div>
        </BrowserRouter >
      </Provider>
    );
  }
}

export default App;
