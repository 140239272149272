import React, { Component } from 'react';
import './CancellationPopup.css';
import { withRouter } from 'react-router';
import prescriptionsService from '../../services/prescriptions.service';

class CancellationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            elementsPerPage: 10,
            data: [],
            totalItems: 0,
            searchWithName: "",
            searchToggle: false,
            searchType: "simple",
            is_loaded: false,
            cancelationRaison: this.props.selectedId,
            cancelationRaisonName: "",
            showPopup: false,
        };

    }
    componentDidMount() {
        this.setState({ is_loaded: false }, () => {
            this.getCancellationReasons();
        });
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    handleChange = (e) => {
        this.setState({ searchWithName: e.target.value, currentPage: 1 }, () => this.getAllPatients());
    };

    getCancellationReasons = async () => {
        try {
            let response = await prescriptionsService.getCancellationReasons()
            this.setState({ data: response.data.cancellation_reasons }, () => console.log(this.state.cancellationReasons))
        } catch (error) {
            console.log(error);
        }
    }

    selectCancellationReasonHandler(id, name) {
        this.setState({ cancelationRaison: id, cancelationRaisonName: name }, () => { this.props.getData(id, name); this.props.closePopup() });
    }


    render() {
        const { data, currentPage, totalItems, is_loaded } = this.state

        return (
            <>
                <div className="PatientsPopup">
                    <div id="popup_container" ref={this.props.reference} className="popup_cnt ui-draggable" style={{ width: "600px" }}>
                        <div className="Popup_header">
                            <p>Liste des raisons d'annulation</p>
                            <i className="fas fa-times Popup_header_close" onClick={this.props.closePopup}></i>
                        </div>
                        <div id="popup_content" className="popup_content">
                            <div>
                                <table className="table table-striped" id="patients">
                                    <thead style={{ display: 'none' }}>
                                        <tr className="PatientsPopup_TR" style={{ display: 'none' }}>
                                            <th style={{ background: "#fff" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((item) => {
                                                return <tr className="data-row PatientsPopup_TR" onClick={() => { this.selectCancellationReasonHandler(item.id, item.reason) }} key={item.id}>
                                                    <td >
                                                        <label htmlFor={`patient${item.id}`} className="radio">
                                                            <input type="radio" name="{`patient${item.id}`}" id={`patient${item.id}`} value={this.state.cancelationRaison} checked={item.id === this.state.cancelationRaison} onChange={() => { this.selectCancellationReasonHandler(item.id) }} />
                                                            <span className="checked" id={`checked${item.id}`}></span>
                                                        </label>
                                                        {item.reason}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="PatientsPopup_footer">
                            <button className="btn_green popup_close" onClick={this.props.closePopup}>Annuler</button>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default withRouter(CancellationPopup);
