import React, { Component } from 'react';
import '../../CSS/all_38.css'
import './TopOptions.css'

class TopOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {

        const { title } = this.props;
        return (
            <div className="top_options_wrapper" style={{position: "sticky"}}>
                <div className="wrapper">
                    <div className="container4">
                        <div className="grid4">
                            <h1 className="Top_options_title">{title}</h1>
                            <div className="top_options_btns">
                                {
                                    this.props.children
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopOptions;