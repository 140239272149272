import React, { Component } from 'react';
import { Chart } from 'react-charts'
import Header from '../../Helpers/Header/Header';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import moment from 'moment';
import InfoCard from '../../Helpers/InfoCard/InfoCard';
import prescriptionsService from '../../services/prescriptions.service';
import DatePicker from "react-datepicker";
import userService from '../../services/user.service';
import Store from '../../Store/configStore';
import "react-datepicker/dist/react-datepicker.css";
import './Repports.css';

const thead = [
    { property: "id", label: "ID de l’ordonnance", type: "string" },
    { property: "patient", label: "Nom du patient", type: "string" },
    { property: "status", label: "Statut", type: "integer" },
    { property: "updatedAt", label: "Date du dernier statut", type: "integer" },
]

class Repports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: "DESC",
            orderBy: "updatedAt",
            fields: {
                ...this.fieldsCopy
            },
            reportsDataTotals: null,
            tableData: [],
            startDate: new Date(),
            country: null
        }
        this.handleSerieChange = this.handleSerieChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    fieldsCopy = {
        status: '',
        endDate: new Date(),
        startDate: moment(new Date()).subtract(1, 'y').toDate()
    }

    componentDidMount() {
        this.getAllReports()
        this.setState({ country: Store?.getState()?.handleCurrentCountry?.currentCountry },()=>{
            if (this.state.country) {
                if (this.state.country === "ma") {
                    window.location.href = '/home'
                } 
            }
        })
    }

    getAllReports = async () => {
        const { fields, order, orderBy } = this.state;
        try {
            let response = await prescriptionsService.getAllReports(fields, order, orderBy);
            this.setState({ is_loaded: true, reportsDataTotals: response.data.totals, tableData: response.data.prescriptions }, () => console.log(this.state.tableData));
        } catch (error) {
            console.log(error);
        }
    }

    // prepareTableData(data) {
    //     let results = [];
    //     let prescriptions = [];

    //     if (data.canceledPrescriptions.total !== 0) {
    //         prescriptions = data.canceledPrescriptions.prescriptions
    //     }
    //     if (data.deliveredPrescriptions.total !== 0) {
    //         prescriptions = prescriptions.concat(data.deliveredPrescriptions.prescriptions)
    //     }
    //     if (data.newPrescriptions.total !== 0) {
    //         prescriptions = prescriptions.concat(data.newPrescriptions.prescriptions)
    //     }
    //     if (data.pendingPrescriptions.total !== 0) {
    //         prescriptions = prescriptions.concat(data.pendingPrescriptions.prescriptions)
    //     }
    //     if (data.readyPrescriptions.total !== 0) {
    //         prescriptions = prescriptions.concat(data.readyPrescriptions.prescriptions)
    //     }
    //     prescriptions.map((el) => {
    //         let A = {
    //             ...el,
    //             patientName: el.patient.firstName + " " + el.patient.lastName,
    //             updatedAt: moment(el.updatedAt).format("YYYY-MM-DD à HH:mm")
    //         }
    //         results.push(A)
    //     })

    //     return results;
    // }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy }, () => this.getAllReports());
    };

    setStartDate = () => {
        this.setState({ startDate: new Date })
    }

    handleSerieChange = e => {
        this.setState({ seriesId: e.target.value }, () => console.log(this.state.seriesId))
    }

    render() {
        const { is_loaded, tableData, fields, reportsDataTotals } = this.state
        const data = [
            {
                label: 'Series 1',
                data: [[0, 1], [1, 3], [2, 4], [3, 3], [4, 6]]
            },
            {
                label: 'Series 2',
                data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
            }
        ]
        const axes = [
            { primary: true, type: 'linear', position: 'bottom' },
            { type: 'linear', position: 'left' }
        ]
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return (
            <>
                <Header />
                <TopOptions title="Rapport sur ordonnance" />
                <div className="Repports">
                    <div className="Profile">
                        <div className="ParametresOrdonnance">
                            <div className="bloc_header">
                                <h2>Options des rapports</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div className="input_factur_group" >
                                        <p className="facture_patient_title">Statut</p>
                                        <select className="form-control select_facture_custom " onChange={this.handleChange("status")} value={fields["status"]} style={{ backgroundColor: '#fff' }} >
                                            <option value="">Tout</option>
                                            <option value="Nouvelle">Nouvelle</option>
                                            <option value="En cours">En cours</option>
                                            <option value="Prête">Prête</option>
                                            <option value="Livrée">Livrée</option>
                                            <option value="Annulée">Annulée</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="input_date_rapport">
                                    <div className="form_row" style={{ width: '49%' }}>
                                        <p className="facture_patient_title">Date de debut</p>
                                        <input type="date" name="startDate" className=" form-control  popup_form_control" autoComplete="off" id="startDate" onChange={this.handleChange("startDate")} value={moment(fields["startDate"]).format("YYYY-MM-DD")} />
                                    </div>
                                    {/* <div className="form_row" style={{ width: '49%' }}>
                                        <p className="facture_patient_title">Date de fin</p>
                                        <DatePicker
                                            className=" form-control  popup_form_control"
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                            }) => (
                                                    <div style={{
                                                        margin: 10,
                                                        display: "flex",
                                                        justifyContent: "center"
                                                    }}
                                                    >
                                                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </button>
                                                        <select value={date} onChange={({ target: { value } }) => changeYear(value)} >
                                                            {moment(new Date).format('YYYY').map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            // value={months[getMonth(date)]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(months.indexOf(value))
                                                            }
                                                        >
                                                            {months.map(option => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </button>
                                                    </div>
                                                )}
                                            selected={startDate}
                                            onChange={date => this.setStartDate(date)}
                                        />
                                    </div> */}
                                    <div className="form_row" style={{ width: '49%' }}>
                                        <p className="facture_patient_title">Date de fin</p>
                                        <input type="date" name="endDate" className=" form-control  popup_form_control" autoComplete="off" id="endDate" onChange={this.handleChange("endDate")} value={moment(fields["endDate"]).format("YYYY-MM-DD")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="statistiques">
                        <div className="statistic">
                            <p>Nouvelles ordonnances</p>
                            <p>{fields.status === "Nouvelle" || fields.status === "" ? reportsDataTotals?.newPrescriptions : "0"}</p>
                        </div>
                        <div className="statistic">
                            <p>Ordonnances en cours</p>
                            <p>{fields.status === "En cours" || fields.status === "" ? reportsDataTotals?.pendingPrescriptions : '0'}</p>
                        </div>
                        <div className="statistic">
                            <p>Ordonnances prêtes</p>
                            <p>{fields.status === "Prête" || fields.status === "" ? reportsDataTotals?.readyPrescriptions : '0'}</p>
                        </div>
                        <div className="statistic">
                            <p>Ordonnances livrées</p>
                            <p>{fields.status === "Livrée" || fields.status === "" ? reportsDataTotals?.deliveredPrescriptions : '0'}</p>
                        </div>
                        <div className="statistic">
                            <p>Ordonnances annulées</p>
                            <p>{fields.status === "Annulée" || fields.status === "" ? reportsDataTotals?.cancelledPrescriptions : '0'}</p>
                        </div>
                    </div>
                    {/* <div className="Profile">
                        <div className="ParametresOrdonnance">
                            <div className="bloc_header">
                                <h2>Mouvement des statuts </h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid"
                                    style={{
                                        width: '100%',
                                        height: '300px'
                                    }}
                                >
                                    <Chart data={data} axes={axes} />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div style={{ paddingBottom: "15px" }}>
                        <div className="Profile">
                            <InfoCard
                                title="Historique des statuts"
                                thead={thead}
                                tbody={{
                                    dataIsLoaded: is_loaded,
                                    data: tableData ? tableData : [],
                                    action: this.odronnanceOfPatient
                                }}
                                // tfoot={{
                                //     colSpan: 4,
                                //     pageNumber: filescurrentPage,
                                //     totalItems: filesTotalItems,
                                //     PrevPage: () => this.setState({
                                //         filescurrentPage: filescurrentPage - 1
                                //     },
                                //         () => {
                                //             this.getFiles();
                                //         }),
                                //     nextPage: () => this.setState({
                                //         filescurrentPage: filescurrentPage + 1
                                //     },
                                //         () => {
                                //             this.getFiles();
                                //         }),
                                // }}
                                {...this.props}>
                            </InfoCard>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Repports;
