import React, { Component } from 'react';
import AsyncSelect from "react-select/async";
import "./style.css";
import axios from "axios";
import mapPinForm from "../../assets/map-pin.svg";
import { api_url } from '../../../../../config';

const INITIAL_FIELDS = {
    data: [],
    loading: false,
    selectedItems: [],
    lastSelectedItem: [],
    error: null,
}

const authAxios = axios.create({
    baseURL: api_url + "cities",
});


class SearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.country !== this.props.country) {
            this.setState({ data: [] }, () => this.preload())
        }
    }

    preload = () => {
        this.getAll();
    }

    
    getAll = async (inputValue = null, callback = () => { }) => {
        try {
            let params = {
                page: 1,
                limit: 20,
            };
            let country= this.props.country
            if (inputValue) {
                params = {
                    ...params,
                    name: inputValue,
                }
            }
            this.setState({ loading: true })
            const response = await authAxios.get(`?country=${country}`, {
                params: params
            })
            this.setState({ loading: false })
            this.setState({ data: response?.data?.cities ?? [] }, () => {
                callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            })
        } catch (error) {
            this.setState({ loading: false })
        }
    }

    render() {
        const { data, error } = this.state;
        const { onChange, value } = this.props;
        return (
            <div className="groupInput Item async-select">
                <label> Ville de la pharmacie </label>
                <div className="input" style={{ padding: "0px 10px" }}>
                    <div className="iconInput">
                        <img src={mapPinForm} alt="" />
                    </div>
                    <AsyncSelect
                        value={value}
                        defaultValue={value}
                        name="items"
                        loadOptions={this.getAll}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Entrez la ville ici …"
                        onChange={onChange}
                        defaultOptions={data?.map((item, index) => ({ value: item.id, label: item.name }))}
                    />
                </div>
            </div>

        );
    }
}

export default SearchSelect;


