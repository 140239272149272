import axios from "axios";
import { api_url } from "../config";
import authHeader from "./auth-header";
import authService from "./auth.service";


const authAxios = axios.create({
    baseURL: api_url + "prescriptions",
    headers: authHeader(),
});

const cancelAuthAxios = axios.create({
    baseURL: api_url,
    headers: authHeader(),
});

class PrescriptionsService {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                if (401 === error.response.status) {
                    if (error.response.data.message === "code1") {
                        localStorage.clear();
                        window.location.href = '/login'
                    } else if (error.response.data.message === "code2") {
                        const originalRequest = error.config;
                        try {
                            await authService.refresh();
                            window.location.reload();
                        } catch (error) {
                            console.log(error)
                            // localStorage.clear();
                            // window.location.href = '/login';
                        }
                    } else if (error.response.data.message === "code3") {
                        localStorage.clear();
                        window.location.href = '/login'
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    getAllPrescriptions(data, page, limit, order, orderBy) {
        return authAxios.post(`/get?page=${page}&limit=${limit}&order=${order}&order_by=${orderBy}`, data, { withCredentials: true });
    }

    getPrescriptionById(id) {
        return authAxios.get(`/${id}`, { withCredentials: true });
    }

    getPrescriptionsKeyFigures() {
        return authAxios.get(`/key-figures`, { withCredentials: true });
    }

    cancelPrescription(id, data) {
        return authAxios.patch(`/${id}/cancel`, data, { withCredentials: true });
    }

    changeStatusPrescription(id, data) {
        return authAxios.patch(`/${id}/change-status`, data, { withCredentials: true });
    }

    getCancellationReasons() {
        return cancelAuthAxios.get(`/cancellation-reasons`, { withCredentials: true })
    }

    getAllReports(data, order, orderBy) {
        return authAxios.post(`/reports?order=${order}&order_by=${orderBy}`, data, { withCredentials: true });
    }
}

export default new PrescriptionsService();
