import React, { Component } from 'react';
import Header from '../../Helpers/Header/Header';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import InfoCard from '../../Helpers/InfoCard/InfoCard';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { PIC_URL } from '../../config';
import prescriptionsService from '../../services/prescriptions.service';
import CancellationPopup from '../../Helpers/CancellationPopup/CancellationPopup';
import CommentPopup from '../../Helpers/CommentPopup/CommentPopup';
import Store from '../../Store/configStore';
import './Ordonnance.css';

const thead = [
    { property: "actionBy", label: "Créée par", type: "string" },
    { property: "status", label: "Statut", type: "integer" },
    { property: "actionDate", label: "Date du dernier statut", type: "string" },
]

const gutter = 2;

const Gallery = (props) => (
    <div
        style={{
            overflow: "hidden",
            marginLeft: -gutter,
            marginRight: -gutter,
        }}
        {...props}
    />
);

const Image = (props) => (
    <div style={{
        backgroundColor: "#eee",
        boxSizing: "border-box",
        float: "left",
        margin: gutter,
        overflow: "hidden",
        position: "relative",
        width: "100px",
        height: "100px",
        ":hover": {
            opacity: 0.9,
        },
    }
    }
        {...props}
    />
);

class Ordonnance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelationRaisonId: null,
            cancelationRaisonName: null,
            showPopup: false,
            showCommentPopup: false,
            currentPage: 1,
            elementsPerPage: 20,
            order: "DESC",
            orderBy: "id",
            statusHistory: [],
            modalIsOpen: false,
            is_loaded: false,
            lightboxIsOpen: false,
            selectedIndex: 0,
            prescriptionPictures: [],
            prescription: null,
            cancellationReasons: null,
            buttonData: null,
            secButtonData: null,
            prescriptionId: 0,
            changeStatusComment: null,
            commentStatus: null,
            cancellationReason: null
        };
        this.getselectedCancelationData = this.getselectedCancelationData.bind(this);
        this.getchangeStatusData = this.getchangeStatusData.bind(this);
    }

    componentDidMount() {
        document.title = "Ordonnances - Sobrus Pharma";
        const id = this.props.match.params.id;
        this.getPatientLocalisation()
        this.setState({ prescriptionId: id, country: Store?.getState()?.handleCurrentCountry?.currentCountry }, () => {
            if (this.state.country) {
                if (this.state.country === "ma") {
                    window.location.href = '/home'
                }
            }
            this.getAllPrescriptions();
        });
    }

    getPrescriptionById = async (id) => {
        try {
            let respose = await prescriptionsService.getPrescriptionById(id)
            this.setState({ prescription: respose.data, statusHistory: respose.data?.statusHistory, is_loaded: true, prescriptionPictures: respose.data?.pictures }, () => {
                if (this.state.prescription.status === "Nouvelle") {
                    this.setState({ buttonData: "En cours" })
                } else if (this.state.prescription.status === "En cours") {
                    this.setState({ buttonData: "Prête", secButtonData: "Prête partiellement" })
                } else if (this.state.prescription.status === "Prête partiellement") {
                    this.setState({ buttonData: "Livrée", secButtonData: "", commentStatus: respose.data?.statusHistory[0]?.comment })
                } else if (this.state.prescription.status === "Prête") {
                    this.setState({ buttonData: "Livrée", secButtonData: "" })
                } else if (this.state.prescription.status === "Annulée") {
                    this.setState({ buttonData: null, secButtonData: null, cancellationReason: respose.data?.statusHistory[0]?.cancellationReason?.reason })
                } else if (this.state.prescription.status === "Livrée") {
                    this.setState({ buttonData: null, secButtonData: null })
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };

    toggleLightbox = (selectedIndex) => {
        this.setState((state) => ({
            lightboxIsOpen: !state.lightboxIsOpen,
            selectedIndex,
        }));
    };

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    }

    toggleCommentPopup() {
        this.setState({
            showCommentPopup: !this.state.showCommentPopup,
        });
    }

    getselectedCancelationData(id, name) {
        this.setState({ cancelationRaisonId: id, cancelationRaisonName: name }, () => {
            this.cancelPrescription(this.state.prescriptionId)
        })
    }

    getchangeStatusData(name) {
        this.setState({ changeStatusComment: name }, () => {
            this.changeStatusPrescriptionPartiel(this.state.prescriptionId)
        })
    }

    cancelPrescription = async (id) => {
        try {
            const data = {
                cancellationReasonId: this.state.cancelationRaisonId
            }
            await prescriptionsService.cancelPrescription(id, data)
            this.getPrescriptionById(this.state.prescriptionId);
        } catch (error) {
            console.log(error);
        }
    }

    changeStatusPrescription = async (id) => {
        try {
            let data = {}
            if (this.state.prescription.status === "En cours") {
                data = {
                    status: this.state.buttonData
                }
            } else {
                data = {
                    status: this.state.buttonData
                }
            }
            await prescriptionsService.changeStatusPrescription(id, data);
            this.getPrescriptionById(this.state.prescriptionId);
        } catch (error) {
            console.log(error);
        }
    }

    changeStatusPrescriptionPartiel = async (id) => {
        try {
            const data = {
                status: this.state.secButtonData,
                comment: this.state.changeStatusComment
            }
            await prescriptionsService.changeStatusPrescription(id, data);
            this.getPrescriptionById(this.state.prescriptionId);
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { is_loaded, statusHistory, prescription, prescriptionPictures, lightboxIsOpen, selectedIndex, buttonData, secButtonData, prescriptionId, commentStatus, cancellationReason } = this.state;
        const images = prescriptionPictures.map((pic, key) => {
            return {
                source: PIC_URL + '/' + pic.pictureName,
                caption: pic.pictureName,
            };
        });
        return (
            <div className="OrdonnancePage_container">
                <Header />
                <TopOptions title={`Ordonnance N°${prescription?.id}`}>
                    {prescription?.status === "Livrée" || prescription?.status === "Annulée" ? '' : <button target="_blank" id="printer" className="btn_white" onClick={() => this.togglePopup()}>Annuler</button>}
                    {secButtonData ? <button target="_blank" id="printer" className="btn_green" onClick={() => this.toggleCommentPopup()}>{secButtonData}</button> : ''}
                    {buttonData ? <button target="_blank" id="printer" className="btn_bleu" onClick={() => this.changeStatusPrescription(prescriptionId)}>{buttonData}</button> : ''}
                </TopOptions>
                <div className="OrdonnancePage">
                    <div style={{ width: "70%" }}>
                        <div className="Profile">
                            <div className="ParametresOrdonnance">
                                <div className="bloc_header">
                                    <h2>Ordonnance</h2>
                                </div>
                                <div className="bloc_content">
                                    <div className="ParametresGrid">
                                        <div className="ParametresGridDetail">
                                            <div className="user_detail_row">
                                                <span>Prénom du patient</span>
                                                <p>{prescription ? prescription?.patient?.firstName : ""}</p>
                                            </div>
                                            <div className="user_detail_row">
                                                <span>ID de l’ordonnance</span>
                                                <p>{prescription?.id}</p>
                                            </div>
                                            {/* <div className="user_detail_row">
                                                <span>Téléphone du patient</span>
                                                <p>{prescription?.patient?.mobileNumber}</p>
                                            </div> */}
                                        </div>
                                        <div className="ParametresGridDetail">
                                            <div className="user_detail_row">
                                                <span>Nom du préparateur</span>
                                                {/* <p>{prescription?.patient?.mobileNumber}</p> */}
                                                <p></p>
                                            </div>
                                            {commentStatus && <div className="user_detail_row">
                                                <span>Commentaire</span>
                                                <p>{commentStatus}</p>
                                                <p></p>
                                            </div>}
                                            {
                                                cancellationReason && <div className="user_detail_row">
                                                    <span>Commentaire</span>
                                                    <p>{cancellationReason}</p>
                                                    <p></p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Profile">
                            <InfoCard
                                title="Historique des statuts"
                                thead={thead}
                                tbody={{
                                    dataIsLoaded: is_loaded,
                                    data: statusHistory ? statusHistory : [],
                                    action: ''
                                }}
                                {...this.props}>
                            </InfoCard>
                        </div>
                    </div>


                    <div style={{ width: "29%", height: "100%", marginTop: '0px' }}>
                        <div>
                            <div className={prescription?.status === "Nouvelle" ? "NouvelleColor" : prescription?.status === "Livrée" ? "LivrColor" : prescription?.status === "En cours" ? "EncourColor" : prescription?.status === "Prête" || prescription?.status === "Prête partiellement" ? "PreColor" : "AnnuColor"} >
                                <span className="Color_span">{prescription?.status}</span>
                            </div>
                        </div>

                        <div className="InfoCard">
                            <div className="bloc_header">
                                <h2>Photos de l'ordonnance</h2>
                            </div>
                            <div className="">
                                <table className="table">
                                    <tbody>
                                        <div style={{ padding: "5px" }}>
                                            <Gallery>
                                                {prescriptionPictures.map((picture, j) => (
                                                    <Image onClick={() => this.toggleLightbox(j)} key={j}>
                                                        <img
                                                            alt={picture.pictureName}
                                                            src={PIC_URL + '/' + picture.pictureName}
                                                            style={{
                                                                objectFit: "cover",
                                                                cursor: "pointer",
                                                                width: "auto",
                                                                height: "100%",
                                                            }}
                                                        />
                                                    </Image>
                                                ))}
                                            </Gallery>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="InfoCard">
                            <div className="bloc_header">
                                <h2>Informations de traçabilité</h2>
                            </div>
                            <div className="">
                                <table className="table">
                                    <tbody>
                                        <tr className="TR">
                                            <td className="TD">Créée le </td>
                                            <td className="TD"> {prescription ? new Intl.DateTimeFormat("fr-Fr", {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit"
                                            }).format(new Date(prescription?.createdAt)) : ""}
                                            </td>
                                        </tr>
                                        <tr className="TR">
                                            <td className="TD">Créée par</td>
                                            <td className="TD"> {prescription?.createdBy?.firstName}</td>
                                        </tr>
                                        <tr className="TR">
                                            <td className="TD">Modifié le</td>
                                            <td className="TD"> {prescription ? new Intl.DateTimeFormat("fr-Fr", {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit"
                                            }).format(new Date(prescription?.updatedAt)) : ""}  </td>
                                        </tr>
                                        <tr className="TR">
                                            <td className="TD">Modifié par </td>
                                            <td className="TD">{prescription?.updatedBy?.firstName} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalGateway>
                    {lightboxIsOpen ? (
                        <Modal onClose={this.toggleLightbox}>
                            <Carousel
                                // components={{ FooterCaption }}
                                currentIndex={selectedIndex}
                                // formatters={{ getAltText }}
                                views={images}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>

                {
                    this.state.showPopup &&
                    <CancellationPopup
                        history={this.props.history}
                        reference={this.setWrapperRef}
                        closePopup={this.togglePopup.bind(this)}
                        getData={this.getselectedCancelationData}
                        selectedId={this.state.cancelationRaisonId}
                    />
                }
                {
                    this.state.showCommentPopup &&
                    <CommentPopup
                        history={this.props.history}
                        reference={this.setWrapperRef}
                        closePopup={this.toggleCommentPopup.bind(this)}
                        getData={this.getchangeStatusData}
                        changeStatusComment={this.state.changeStatusComment}
                    />
                }
            </div>
        );
    }
}

export default Ordonnance;
