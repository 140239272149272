import axios from "axios";
import { api_url } from "../config";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "connection-history",
});

const prePogAxiois = axios.create({
  baseURL: api_url + "pre-registration",
});

class ConnectionService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (401 === error.response.status) {
          if (error.response.data.message === "code1") {
            localStorage.clear();
            window.location.href = '/login'
          } else if (error.response.data.message === "code2") {
            try {
              await authService.refresh();
              window.location.reload();
            } catch (error) {
              console.log(error)
            }
          } else if (error.response.data.message === "code3") {
            localStorage.clear();
            window.location.href = '/login'
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  preRegistration(data) {
    return prePogAxiois.post('', data, {});
  }

  getAllConnectionHistory(page, limit) {
    return authAxios.get(`/get?page=${page}&limit=${limit}`, { withCredentials: true });
  }
}

export default new ConnectionService();
