import React, { Component } from 'react';
import ButtonLeader from '../../Helpers/ButtonLeader/ButtonLeader';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';

class generalInfoPharmasistEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacieData: null,
            slug: null,
            fields: {
                ...this.fieldsData
            },
            errors: {},
            cities: [],
            sectors: [],
            selectedCity: null,
            selectedsector: null,
            cityId: null,
            sectorId: null,
            buttonLeader: false,
            loader: true,
            pharmacistId_error: false
        }
    }

    fieldsData = {
        firstName: '',
        lastName: '',
        degreeSchool: '',
        degreeYear: '',
    }

    componentDidMount() {
        const slug = this.props && this.props.location.state.slug
        this.getPharmacieByslug(slug);
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            let fieldsCopy = this.state.fields
            fieldsCopy = {
                ...response.data.pharmacy,
                firstName: response.data.pharmacy?.pharmacist?.firstName,
                lastName: response.data.pharmacy?.pharmacist?.lastName,
                degreeSchool: response.data.pharmacy?.pharmacist?.degreeSchool,
                degreeYear: response.data.pharmacy?.pharmacist?.degreeYear,
            }
            this.setState({
                fields: fieldsCopy, slug,
                pharmacieData: response.data.pharmacy,
                loader: false
            })
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy });
    };

    suggestDataPharmacists = async () => {
        this.setState({ buttonLeader: true }, async () => {
            try {
                const data = {
                    pharmacist_suggestion: {
                        pharmacistId: this.state.pharmacieData?.pharmacist?.id,
                        degreeSchool: this.state.fields.degreeSchool,
                        degreeYear: this.state.fields.degreeYear,
                        firstName: this.state.fields.firstName,
                        lastName: this.state.fields.lastName,
                    }
                }
                await pharmaciesServices.suggestDataPharmacists(data)
                this.props.history.push('/account/generalinfo_pharmasist', {
                    successMessage: true,
                })
            } catch (error) {
                if (500 === error.response.status) {
                    if (error.response?.data?.message.includes("pharmacistId")) {
                        this.setState({ pharmacistId_error: true }, () => {
                            setTimeout(() => {
                                this.setState({ pharmacistId_error: false })
                            }, 1000);
                        })
                    }
                }
                console.log();
            }
        }, () => {
            this.setState({ buttonLeader: false })
        })
    }

    render() {
        const { fields, loader, buttonLeader, pharmacistId_error } = this.state
        return (
            <>
                {loader && <Loader />}
                {
                    pharmacistId_error && <div className="pharmacistId_error" >
                        <i className="far fa-times-circle"></i>
                        <p>Une erreur est survenue. Veuillez contacter notre service client au 05 30 500 500</p>
                    </div>
                }
                <Header />
                <TopOptions title="Informations pharmacien">
                    {!buttonLeader ? <button id="printer" className="btn_bleu" onClick={() => this.suggestDataPharmacists()}>Enregistrer</button> :
                        <button className="btn_bleu_leader" ><ButtonLeader /></button>}
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Informations pharmacien</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div className="ParametresGridDetail">
                                        <div className="form_row">
                                            <label htmlFor="accountName">Université du diplôme :</label>
                                            <input placeholder="Université du diplôme" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("degreeSchool")} value={fields["degreeSchool"]} />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="accountName">Prénom du pharmacien :</label>
                                            <input placeholder="Prénom du pharmacien" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("firstName")} value={fields["firstName"]} />
                                        </div>
                                    </div>
                                    <div className="ParametresGridDetail">
                                        <div className="form_row">
                                            <label htmlFor="accountName">Année du diplôme :</label>
                                            <input placeholder="Année du diplôme" type="date" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("degreeYear")} value={fields["degreeYear"]} />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="accountName">Nom du pharmacien :</label>
                                            <input placeholder="Nom du pharmacien" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("lastName")} value={fields["lastName"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </>
        );
    }
}

export default generalInfoPharmasistEdit;
