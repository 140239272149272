import React from "react";
//import lineMobile from '../assets/line-mobile.svg';
import lineMobile from "../assets/mobile-line.svg";
import lineDesk from "../assets/desktop-line.svg";

function Section3() {
    return (
        <section className="section-3 col-12" id="section3">
            <div className="section-3-title" data-aos='slide-up'>
                <h3 className="h3">- Comment ça marche ?</h3>
                <h1 className="h1">
                    Comment <strong>ça fonctionne?</strong>
                </h1>
            </div>

            <div className="line" data-aos='zoom-out-up'>
                <img className="line-img" src={lineDesk} alt="" />
                <img className="line-mobile-img" src={lineMobile} alt="" />
            </div>
            {/* <div>
                <div className="step-1 step">
                    <img src={step1} alt="" />
                    <p>Je reçois l’ordonnance</p>
                </div>
                <div className="step-2 step">
                    <img src={step2} alt="" />
                    <p>Je la prépare</p>
                </div>
                <div className="step-3 step">
                    <img src={step3} alt="" />
                    <p>Mon patient est informé</p>
                </div>
                <div className="step-4 step">
                    <img src={step4} alt="" />
                    <p>Il vient récupérer sa commande</p>
                </div>
            </div> */}
        </section>
    );
}

export default Section3;
