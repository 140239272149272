import React from "react";
import Visibility from '../assets/visibility.svg';

function Section7() {
    return(
        <section className="section-7 col-12" id="section7">

            <div className="container row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content" data-aos="fade-up-right">
                    <div className="ImgContent">
                        <img src={Visibility} alt="" />
                    </div>
                </div>
                
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content" data-aos="fade-up-left">
                    <h3 className="h3">- Présentation</h3>
                    <h1 className="h1">
                        Le module de <strong>visibilité ?</strong>
                    </h1>
                    <p className="p">
                        Le module <strong>Visibilité</strong> de <strong>Sobrus Pharma</strong> vous <strong>rapproche de vos patients grâce à l’annuaire interactif de pharmacies MonPharmacien</strong>. 
                        <br /><br />
                        C’est très simple, il vous suffit de <strong>créer et de compléter la page de votre pharmacie</strong> pour permettre à <strong>vos patients de vous trouver facilement</strong>.
                    </p>
                </div>
            </div>

        </section>
    );
}

export default Section7;