import React, { Component } from "react";
import moment from "moment";
import ContentLoader from "react-content-loader";
import PropTypes from 'prop-types';
import './TableModel.css'
import { TablePagination } from "../pagination/Pagination";
import { AiOutlineReload } from "react-icons/ai";
import { ImSearch } from "react-icons/im";

const is = (item) => {
  return item && (item)
}

const ACTIONS_CLASS_NAME = {
  Modifier: "fas fa-pencil-alt Modifie_icon",
  Visualiser: "fas fa-eye Modifie_icon",
  Imprimer: "fas fa-file-pdf Modifie_icon"
}

class TableModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.tbody.data,
      searchType: "simple",
      searchToggle: false,
      dataIsLoaded: false,
      search: false,
      showArrows: 0,
      clickedColumn: -1
    };
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
    }
  }

  searchToggle(searchTypeParam) {
    const { searchToggle, searchType } = this.state;
    this.setState({ searchType: searchTypeParam, searchToggle: searchToggle && searchType == searchTypeParam ? false : true });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      });
    }
    if (prevProps.tbody.dataIsLoaded !== this.props.tbody.dataIsLoaded) {
      this.setState((prevState) => ({
        ...prevState,
        tbody: {
          ...prevState.tbody,
          dataIsLoaded: this.props.tbody.dataIsLoaded
        }
      }))
    }
  }

  getFormateData = (object, item) => {
    switch (item.type) {
      case "date":
        return object[item.property] ? moment(object[item.property]).format("YYYY-MM-DD à HH:mm") : ""
        break;
      case "status":
        return <p className={object[item.property] === "Livrée" ? "livButton" : object[item.property] === "Nouvelle" ? "nouButton" : object[item.property] === "En cours" ? "encButton" : object[item.property] === "Prétes" ? "preButton" : object[item.property] === "Annulée" ? "annButton" : "preButton"}>{object[item.property]}</p>
        break;
      default:
        return object[item.property]
    }
  }
  colClicked = (key, cb) => {
    if (this.state.showArrows === 0 || this.state.showArrows === 1) {
      this.setState({
        clickedColumn: key,
        showArrows: 2
      }, () => cb())
    } else {
      this.setState({
        clickedColumn: key,
        showArrows: 1
      }, () => cb())
    }
  }

  render() {
    const {
      title,
      onReload,
      thead,
      tbody,
      tfoot,
      BasicSearchComponent,
      AdvancedSearchComponent,
      basicSearchToggle,
      advancedSearchToggle,
      searchToggleFunc,
      allowedEditTable,
      onClickColumn
    } = this.props;

    return (
      <div className="Bloc">
        <div className="bloc_header">
          <h2>{is(title)}</h2>
          <div className="bloc_header_btns">
            {BasicSearchComponent && <ImSearch onClick={() => searchToggleFunc("simple")} className="sprite-bloc-search fas fa-search" />}
            {/* required */}
            <AiOutlineReload onClick={() => { this.setState({ showArrows: 0, clickedColumn: -1 }, () => { onReload() }) }} className="sprite-bloc-reload fas fa-redo-alt" /><a ></a>
            {AdvancedSearchComponent && <i onClick={() => searchToggleFunc("advanced")} className="sprite-bloc-star fas fa-star" />}
          </div>

        </div>
        <div className="bloc_content table_wrraper">
          <table className="table processableTable" >
            <thead>
              {advancedSearchToggle && (AdvancedSearchComponent)}
              {basicSearchToggle && (BasicSearchComponent)}
              <tr className="table_th_orderable">
                {
                  thead && thead.map((item, key) => {
                    return (
                      <th className={this.state.clickedColumn === key ? "TH FilterCulomn" : "TH"} style={{ width: item.width ? item.width : "" }} onClick={() => { this.colClicked(key, () => onClickColumn(item.property)) }} key={"key_TH_thead" + key}>
                        {
                          item.label
                        }
                        <span className="up_and_down_icons">
                          {this.state.clickedColumn === key ? (this.state.clickedColumn === -1 || this.state.clickedColumn === key) && (this.state.showArrows === 0 || this.state.showArrows === 1) && <a className="OrderUp"></a> : <a className="OrderUp"></a>}
                          {this.state.clickedColumn === key ? (this.state.clickedColumn === -1 || this.state.clickedColumn === key) && (this.state.showArrows === 0 || this.state.showArrows === 2) && <a className="OrderDown"></a> : <a className="OrderDown"></a>}
                        </span>
                      </th>
                    );
                  })
                }
                {
                  tbody && tbody.actions.length != 0 && (<th className="table_actions TH" />)
                }
              </tr>
            </thead>
            {tbody && !tbody.dataIsLoaded && <tbody>{<Loading columns={thead} />}</tbody>}
            {tbody && tbody.dataIsLoaded && (
              <tbody>
                {tbody && tbody.data.map((object, index) => {
                  return (
                    <tr className="TR" key={"key_" + index}>
                      {
                        thead && thead.map((item, index) => (
                          <td className="TD" onClick={() => tbody.onClickRow(object.id)} key={"key_TD_" + index}>{this.getFormateData(object, item)}</td>
                        ))
                      }
                      {
                        tbody && tbody.actions.length != 0 && (
                          <td className="table_action TD">
                            {
                              allowedEditTable ? (
                                tbody && tbody.actions.map((item, key) => {
                                  return allowedEditTable[index] ? (
                                    <a onClick={() => item.onClick(object.id)}
                                      className={ACTIONS_CLASS_NAME[item.title]}
                                      title={item.title}
                                      data-confirm_msg data-removeable_element="parents::tr" target="_blank"
                                      key={"key_A_Modifier" + key}
                                    />
                                  ) : (
                                      item.title != "Modifier" ? (
                                        <a onClick={() => item.onClick(object.id)}
                                          className={ACTIONS_CLASS_NAME[item.title]}
                                          title={item.title}
                                          data-confirm_msg data-removeable_element="parents::tr" target="_blank"
                                          key={"key_A_Modifier" + key}
                                        />) : ("")
                                    )
                                }
                                ))
                                : (
                                  tbody && tbody.actions.map((item, index) => {
                                    return <a onClick={() => item.onClick(object.id)}
                                      className={ACTIONS_CLASS_NAME[item.title]}
                                      title={item.title}
                                      data-confirm_msg data-removeable_element="parents::tr" target="_blank"
                                      key={"key_A_" + index}
                                    />
                                  })

                                )
                            }

                          </td>
                        )
                      }
                    </tr>
                  );
                })}
              </tbody>)
            }
            {tfoot && <TablePagination
              colSpan={tfoot.colSpan}
              pageNumber={tfoot.pageNumber}
              totalItems={tfoot.totalItems}
              onPrevBtnClick={() => tfoot.PrevPage()}
              onNextBtnClick={() => tfoot.nextPage()}
            />}
          </table>
        </div>
      </div>
    );
  }
}

TableModel.protoTypes = {
  title: PropTypes.string,
  onReload: PropTypes.bool,
  thead: PropTypes.arrayOf(PropTypes.object),
  tbody: PropTypes.object,
  tfoot: PropTypes.object,
  BasicSearchComponent: PropTypes.element,
  AdvancedSearchComponent: PropTypes.element,
}

export default TableModel;

const Loading = (props) => {
  const { columns } = props;
  return (
    <>
      {[1, 2, 3].map((index) => {
        return (
          <tr className="TR" key={"key_Loading" + index}>
            {
              columns.map(() => (
                <td className="TD">
                  <ContentLoader width="160" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                  </ContentLoader>
                </td>
              ))
            }
            <td className="table_action TD">
              <ContentLoader width="0" height="20">
                <rect x="0" y="0" rx="3" ry="3" width="0" height="20" />
              </ContentLoader>
            </td>
          </tr>
        );
      })}
    </>
  );
}

