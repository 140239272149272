import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import phone from "../assets/phone-red.svg";
import mail from "../assets/mail-red.svg";
import phoneForm from "../assets/phone.svg";
import mailForm from "../assets/mail.svg";
import personForm from "../assets/person.svg";
import mapPinForm from "../assets/map-pin.svg";
import whiteArrow from "../assets/white-arrow.svg";
import copy from "../assets/copy-pink.svg";
import { Link } from "react-router-dom";
import SearchSelect from "./searchSelect/SearchSelect";
import connectionService from "../../../../services/connection.service";
import { toast } from 'react-toastify';
import AOS from 'aos';

function Section9() {
    const textRef = useRef();
    const [value, setValue] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState("ma");
    const [checkConditions, setCheckConditions] = useState(false);
    const [errors, setErrors] = useState(null);
    const [img, setImage] = useState(null)

    useEffect(() => {
        setValue(textRef.current.innerText);
        creatImage()
    }, []);


    const checkDataValidation = () => {

        setErrors(null)
        let errorsCopy = {};
        let formValid = true;
        if (!checkConditions) {
            formValid = false;
        }
        if (firstName?.length === 0) {
            errorsCopy["firstName"] = "Ce champs est requis"
            setErrors(errorsCopy);
            formValid = false;
        } if (lastName?.length === 0) {
            errorsCopy["lastName"] = "Ce champs est requis"
            formValid = false;
        } if (email?.length === 0) {
            errorsCopy["email"] = "Ce champs est requis"
            setErrors(errorsCopy);
            formValid = false;
        } else {
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    email.length - lastDotPos > 2
                )
            ) {
                errorsCopy["email"] = "Cet email est invalide"
                setErrors(errorsCopy);
                formValid = false;
            }
        }
        if (mobileNumber?.length === 0) {
            errorsCopy["mobileNumber"] = "Ce champs est requis"
            setErrors(errorsCopy);
            formValid = false;
        } else if (!mobileNumber.match(/(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/)) {
            errorsCopy["mobileNumber"] = "Numéro invalide"
            setErrors(errorsCopy);
            formValid = false;
        }
        return formValid;
    }

    const initializeData = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setCity(null);
        setErrors(null);
    }

    const onSubmit = async () => {
        if (checkDataValidation()) {
            try {
                let data = {
                    firstName,
                    lastName,
                    mobileNumber,
                    email,
                    cityId: city?.value,
                    country
                }
                const response = await connectionService.preRegistration(data)
                toast.success('Votre inscription a été envoyée avec succès', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                initializeData();
            } catch (error) {
                toast.error('Votre inscription a été échouée', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(error);
            }
        } else {
            if (!checkConditions) {
                toast.error("Merci d'accepter les conditions générales d'utilisation et la politique de confidentialité", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }


    const creatImage = () => {
        let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
        canvasTxt.canvas.width = canvasTxt.measureText('support@sobrus.com').width + 30;
        canvasTxt.canvas.height = 22;
        canvasTxt.canvas.fillStyle = "#0645AD";
        canvasTxt.fillStyle = "#0645AD";
        canvasTxt.canvas.font = "14px Poppins";
        canvasTxt.font = "14px Poppins";
        canvasTxt.fillText("support@sobrus.com", 0, 14);
        setImage(canvasTxt.canvas.toDataURL());
    }

    return (
        <section className="section-9" id="section9">
            <div className="container">
                <h1 className="h1" data-aos="zoom-in">
                    Demandez votre compte d’essai <br />
                    <strong>gratuitement ! </strong>
                </h1>

                <p className="p" data-aos="zoom-in">
                    L’équipe MonPharmacien prendra contact avec vous et répondra à toutes vos
                    questions.
                </p>

                <div className="infos" data-aos="flip-up" data-aos-duration="2000" data-aos-offset="0">
                    <div className="info">
                        <div className="icon">
                            <img src={phone} alt="" />
                        </div>
                        <div className="text">
                            <label>Téléphone</label>
                            <a rel="noreferrer">
                                +33 3 62 27 90 70
                            </a>
                        </div>
                    </div>

                    <div className="info" data-aos="flip-up" data-aos-duration="2000" data-aos-offset="0">
                        <div className="icon">
                            <img src={mail} alt="" />
                        </div>
                        <div className="text">
                            <label>E-mail</label>
                            <CopyToClipboard text={value} style={{ cursor: "pointer" }}>
                                <span className="sobrus-email-footer">
                                    <a ref={textRef}>
                                        support@sobrus.com
                                    </a>
                                    <img src={copy} alt="" />
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>

                <div className="form">
                    <form id="compte" data-aos="flip-up">
                        <div className="group-col">
                            <div className="groupInput Item">
                                <label> Nom *</label>
                                <div className="landing_page_input">
                                    <div className="iconInput">
                                        <img src={personForm} alt="" />
                                    </div>
                                    <input
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        type="text"
                                        name="name"
                                        placeholder="Entrez votre Nom"
                                    />
                                </div>
                                {errors?.lastName?.length !== 0 && <InputError>{errors?.lastName}</InputError>}
                            </div>
                            <div className="groupInput Item">
                                <label>Prénom *</label>
                                <div className="landing_page_input">
                                    <div className="iconInput">
                                        <img src={personForm} alt="" />
                                    </div>
                                    <input
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        type="text"
                                        name="name"
                                        placeholder="Entrez votre Prénom"
                                    />
                                </div>
                                {errors?.firstName?.length !== 0 && <InputError>{errors?.firstName}</InputError>}
                            </div>
                            <div className="groupInput Item">
                                <label> Téléphone *</label>
                                <div className="landing_page_input">
                                    <div className="iconInput">
                                        <img src={phoneForm} alt="" />
                                    </div>
                                    <input
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        type="text"
                                        name="name"
                                        placeholder="Entrez votre téléphone"
                                    />
                                </div>
                                {errors?.mobileNumber?.length !== 0 && <InputError>{errors?.mobileNumber}</InputError>}
                            </div>

                            <div className="group-col">
                                <div className="groupInput Item">
                                    <label> Adresse e-mail * </label>
                                    <div className="landing_page_input">
                                        <div className="iconInput">
                                            <img src={mailForm} alt="" />
                                        </div>
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="text"
                                            name="name"
                                            placeholder="Votre adresse e-mail ici …"
                                        />
                                    </div>
                                    {errors?.email?.length !== 0 && <InputError>{errors?.email}</InputError>}
                                </div>
                            </div>
                            <div className="group-col">
                                <div className="groupInput Item">
                                    <label> Pays </label>
                                    <div className="landing_page_input">
                                        <div className="iconInput">
                                            <img src={mapPinForm} alt="" />
                                        </div>
                                        <select value={country} onChange={(e) => {
                                            setCountry(e.target.value)
                                            if (city !== null) {
                                                setCity(null)
                                            }
                                        }} style={{ width: "100%", background: "transparent" }}>
                                            <option value="ma" >Maroc</option>
                                            <option value="dj" >Djibouti</option>
                                            <option value="sn" >Sénégal</option>
                                        </select>
                                    </div>
                                    {errors?.country?.length !== 0 && <InputError>{errors?.country}</InputError>}
                                </div>
                            </div>
                            <SearchSelect
                                className="form-control SearchInputs"
                                value={city}
                                country={country}
                                placeholder="Entrez la ville"
                                onChange={(option) => { setCity(option) }}
                            ></SearchSelect>
                            <div className="terms" style={{ textAlign: "start", display: "block" }}>
                                <span style={{ color: "#273e4f", fontSize: 13, fontWeight: "300" }} >*Champs obligatoires</span>
                                <p style={{ color: "#273e4f !important", fontSize: 14, fontWeight: "400", marginBottom: '1rem', marginTop: '1rem' }}>Par le biais de ce formulaire, MonPharmacien collecte vos données personnelles en vue de répondre à votre demande suite à l’envoi du formulaire de contact. Ce traitement a fait l’objet d’une déclaration de la CNDP sous le numéro {' '}<strong> D-GC-252/2018.</strong></p>
                                <p style={{ color: "#273e4f !important", fontSize: 14, fontWeight: "400", marginBottom: '1rem' }}>Vous pouvez vous adresser à Sobrus par e-mail à l’adresse suivante :  <a><canvas id="canvasComponent" style={{ display: "none" }} /><img src={img} /></a> pour exercer vos droits d’accès, de rectification et d’opposition conformément aux dispositions de la loi 09-08.</p>
                            </div>
                            <div className="terms">
                                <div className="terms-container">
                                    <input checked={checkConditions} onChange={() => setCheckConditions(!checkConditions)} type="checkbox" className="scales" id="scales" name="cgu" />
                                    <label htmlFor="scales">J'ai lu et approuvé{" "}<Link to="/conditions">les conditions générales d’utilisation</Link>{" "}de MonPharmacien</label>
                                </div>
                                <div className="groupInput Item">
                                    <button style={{ width: "320px" }} type="button" onClick={onSubmit} className="btn btn-customize">
                                        Demander un compte gratuit
                                    <img src={whiteArrow} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="terms">
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Section9;

const InputError = (props) => {
    return (
        <span style={{ color: "#F05A29", fontSize: 13, fontWeight: "300" }}>{props.children}</span>
    )
}