import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

class PharmacyPaymentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tEstablishmentParams: null,
            pharmacieData: null,
            paymentMethods: [],
            paymentMethodsChecked: [],
            pharmacyData: null,
            loader: true
        }
    }

    componentDidMount() {
        this.getPaymentMethods()
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.getPharmacieByslug(response.data.pharmacySlug)
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ paymentMethodsChecked: response.data.pharmacy?.availablePaymentMethods, pharmacyData: response.data.pharmacy, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    getPaymentMethods = async () => {
        try {
            let response = await pharmaciesServices.getPaymentMethods()
            this.setState({ paymentMethods: response.data?.payment_methods }, () => this.getUser())
        } catch (error) {
            console.log(error);
        }
    }

    suggestDataPharmacy = async () => {
        console.log(this.state.paymentMethodsChecked);
        let paymentMethodsCopy = []
        this.state.paymentMethodsChecked.map((item) => paymentMethodsCopy.push(item.id))
        try {
            const data = {
                pharmacy_suggestion: {
                    pharmacyId: this.state.pharmacyData.id,
                    availablePaymentMethods: paymentMethodsCopy,
                }
            }
            await pharmaciesServices.suggestDataPharmacy(data)
            this.props.history.push('/account/pharmacy_payment', {
                successMessage: true,
            })
        } catch (error) {
            console.log(error);
        }
    }

    toggleAdmin = (items) => {
        if (!this.state.paymentMethodsChecked.some(item => items.id === item.id)) {
            this.setState({ paymentMethodsChecked: [...this.state.paymentMethodsChecked, items] })
        } else if (this.state.paymentMethodsChecked.some(item => items.id === item.id)) {
            const index = this.state.paymentMethodsChecked.indexOf(items);
            let newArray = [];
            if (index > -1) {
                this.state.paymentMethodsChecked.splice(index, 1);
                this.setState({ paymentMethodsChecked: [...this.state.paymentMethodsChecked] })
            } else {
                this.state.paymentMethodsChecked.map((item) => {
                    if (item.id !== items.id) {
                        newArray.push(item)
                    }
                })
                this.setState({ paymentMethodsChecked: newArray })
            }
        }
    }

    render() {
        const { paymentMethods, paymentMethodsChecked, loader } = this.state;
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                <Header />
                <TopOptions title="Moyens de paiement acceptés">
                    <button id="printer" className="btn_bleu" onClick={() => this.suggestDataPharmacy()}>Enregistrer</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Moyens de paiement acceptés</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div style={{ display: "flex", flexWrap: 'wrap', width: "100%" }}>
                                        {
                                            paymentMethods.map((items) => {
                                                return <label className="PharmacyServiceEdit_container ParametresFacture_input" id="showHeader" style={{ width: "49%", marginRight: "1%" }} >
                                                    {items?.label === "Cash" ? "Espèce" : items?.label === "Check" ? "Chèque"
                                                        : items?.label === "Debit Card" ? "Carte bancaire" : items?.label === "Credit" ? "Crédit"
                                                            : items?.label === "Bill of Exchange" ? "Lettre de change" : items?.label === "Wire Transfer" ? "Virement bancaire"
                                                                : items?.label === "Other" ? "Autres" : ""}
                                                    <input type="checkbox" id="showHeader" onChange={() => this.toggleAdmin(items)} checked={paymentMethodsChecked.some(item => items.id === item.id)} />
                                                    <span className="checkmark" id="showHeader"></span>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyPaymentEdit;
