import React, { Component } from 'react';
import './Menu.css'
import UpdateIcon from "../../images/sprite_5.png";
import profil_photo from "../../images/profil_photo.png"
import { withRouter } from 'react-router';
import pharmacieIcon from '../../images/Icone MonPharmacien.png'
import userService from '../../services/user.service';
import { RiEditBoxLine } from 'react-icons/ri';
import { MANAGE_PROFILE_URL, MONPHARMACIEN_BASE_URL } from "../../config"


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: null,
            totalPendingItems: 0,
            is_loaded: false
        };
    }

    componentDidMount() {
        this.getUser();
        // this.getAllSuggestions();
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    // getAllSuggestions = async () => {
    //     let searchFields = {
    //         status: { value: 'pending', operator: 'contains' }
    //     }
    //     try {
    //         let response = await ProductServices.getAllSuggestions(searchFields, 1, 1, 'DESC');
    //         this.setState({ is_loaded: true, totalPendingItems: response.data.total });
    //     } catch (error) {
    //         console.warn(error);
    //     }
    // };

    render() {
        return (
            <div className="Menu_container">
                <div className="Compte_infos">
                    <div className="Avatar_container">
                        <img className="Avatar" src={profil_photo} />
                    </div>
                    <h4 className="User_name">{this.state.userData ? this.state.userData.firstName + " " + this.state.userData.lastName : "Chargement en cours..."}</h4>
                    <p className="User_email">{this.state.userData?.email}</p>
                    <a className="Update_btn"><img className="Update_icon" src={UpdateIcon} /></a>
                    <a className="EditBox_container" onClick={() => window.open(MANAGE_PROFILE_URL, '_blank')}>
                        <RiEditBoxLine />
                    </a>
                </div>
                <div className="Links">
                    <h4 className="LinksTitle">Raccourcis</h4>
                    <ul>
                        <li><img className="Panel" src={pharmacieIcon} /><a className="Item_link" onClick={() => window.open(MONPHARMACIEN_BASE_URL + this.state.userData?.pharmacySlug, "_blank")}>Ma page professionnelle</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(Menu);
