import React from 'react';
import { ImSearch } from 'react-icons/im';
import CustomInput from '../CustomInput/CustomInput';
import CustomSelect from '../CustomSelect/CustomSelect';
import './SearchComponent.css';

export const BasicSearchComp = (props) => {
    const { columns, searchType, onSearch, onKeyDown } = props;
    // const DateComp = ({ value, onClick }) => (
    //     <CustomDatePicker error={false} onClick={onClick} defaultValue={value} id="name2" label={"Date"}></CustomDatePicker>
    // );
    return (
        <tr className="table_th_orderable" style={{ font: '12px "OpenSans", sans-serif' }}>
            <th>
                <div className="Search_input_container">
                    <CustomInput
                        id="PatientfirstName"
                        value={columns.id.value}
                        onKeyDown={(e) => { onKeyDown(e) }}
                        onChange={columns.id.onChange}
                        label="Rechercher par ID d'ordonnance"
                    />
                    <div className="Search_component_icon">
                        <i className="fas fa-search"></i>
                    </div>
                </div>
            </th>
            <th>
                <div className="Search_input_container">
                    <CustomInput
                        id="PatientlastName"
                        value={columns.patientName.value}
                        onKeyDown={(e) => { onKeyDown(e) }}
                        onChange={columns.patientName.onChange}
                        label="Rechercher par nom"
                    />
                    <div className="Search_component_icon">
                        <i className="fas fa-search"></i>
                    </div>
                </div>
            </th>
            <th>
                <div className="Search_input_container">
                    <CustomSelect
                        id="OrdonnanceStatus"
                        onKeyDown={(e) => { onKeyDown(e) }}
                        onChange={columns.status.onChange}
                        selectedValue={columns.status.value}
                        label="Rechercher par status"
                    >
                        <option value="">Tout</option>
                        <option value="Nouvelle">Nouvelle</option>
                        <option value="En cours">En cours</option>
                        <option value="Prête">Prête</option>
                        <option value="Prête partiellement">Prête partiellement</option>
                        <option value="Livrée">Livrée</option>
                        <option value="Annulée">Annulée</option>
                    </CustomSelect>
                </div>
            </th>
            <th>
                <div className="Search_input_container">
                    <input type="date" name="entryDate" className="first_responder form-control popup_form_control" autoComplete="off" id="birthDay" onKeyDown={(e) => { onKeyDown(e) }} onChange={columns.updatedAt.onChange} label="Rechercher par date de naissance" value={columns.updatedAt.value} style={{ border: '0px', borderRadius: '6px' }} />
                </div>
            </th>
            {
                searchType === "advanced" ? (<th ></th>) : (<th >
                    <button className="BasicSearchComp_search_btn" onClick={onSearch}>
                        <ImSearch className="fas fa-search BasicSearchComp" />
                    </button>
                </th>)
            }

        </tr>
    );
}


