import React, { Component } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

class ButtonLeader extends Component {

    render() {
        const override = `
            top: -10px;
            right: 10px;
        `;
        return (
            <div className="ButtonLeader">
                <PuffLoader size={20} css={override} color={"#fff"} />
            </div>
        );
    }
}

export default ButtonLeader;
