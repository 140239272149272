import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

class DisplayParamsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPharmacistName: false,
            showPharmacistDegree: false,
            loader: true
        }
    }

    componentDidMount() {
        this.getUser()
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.getPharmacieByslug(response.data.pharmacySlug)
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({
                pharmacyData: response.data.pharmacy,
                showPharmacistName: response.data?.pharmacy?.showPharmacistName,
                showPharmacistDegree: response.data?.pharmacy?.showPharmacistDegree,
                loader: false
            })
        } catch (error) {
            console.log(error);
        }
    }

    updatePharmacyDisplayParams = async () => {
        const { showPharmacistName, showPharmacistDegree } = this.state
        try {
            const data = {
                showPharmacistName: showPharmacistName,
                showPharmacistDegree: showPharmacistDegree,
            }
            await pharmaciesServices.updatePharmacyDisplayParams(data)
            this.props.history.push('/account/display_params', {
                successMessage: true,
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { showPharmacistName, showPharmacistDegree, loader } = this.state
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                <Header />
                <TopOptions title="Paramètres d'affichage">
                    <button id="printer" className="btn_bleu" onClick={() => this.updatePharmacyDisplayParams()}>Enregistrer</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Paramètres d'affichage</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div style={{ display: "flex", flexWrap: 'wrap', width: "100%" }}>
                                        <label className="PharmacyServiceEdit_container ParametresFacture_input" id="showHeader" style={{ width: "49%", marginRight: "1%" }} >Nom du pharmacien
                                            <input type="checkbox" id="showHeader" onChange={() => this.setState({ showPharmacistName: !this.state.showPharmacistName })} checked={showPharmacistName} />
                                            <span className="checkmark" id="showHeader"></span>
                                        </label>
                                        <label className="PharmacyServiceEdit_container ParametresFacture_input" id="showHeader" style={{ width: "49%", marginRight: "1%" }} >Diplôme
                                            <input type="checkbox" id="showHeader" onChange={() => this.setState({ showPharmacistDegree: !this.state.showPharmacistDegree })} checked={showPharmacistDegree} />
                                            <span className="checkmark" id="showHeader"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default DisplayParamsEdit;
