import React, { Component } from 'react';
import './CustomSelect.css'

class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "",
            focus: false,
        };
    }
    componentDidMount() {

    }
    openSelect(event) {
        const fieldInput = this.refs.fieldInput;
        fieldInput.handleMouseDown(event);
    }

    onFocus = () => {
        this.setState({ focus: true });
    }

    onBlur = () => {
        this.setState({ focus: false });
    }

    render() {
        const { focus } = this.state;
        const { error } = this.props;
        return (
            <div className="CustomSelect">
                <div className="Custom_select_input_container">
                    <select onKeyDown={this.props.onKeyDown} onFocus={this.onFocus} onBlur={this.onBlur} className={error && !focus ? ("updateSelect-erreur") : ("updateSelect")} ref='fieldInput' onChange={this.props.onChange} value={this.props.selectedValue} id={this.props.id} required>
                        {this.props.children}
                    </select>
                    <label className="Custom_select_icon" htmlFor={this.props.id}>
                        <i className="fas fa-chevron-down"></i>
                    </label>
                </div>
                {
                    error && !focus && (<div>
                        <div className="Custom_select_error_text">{this.props.errorMessage ? this.props.errorMessage : "Ce champs est obligatoire"}</div>
                    </div>)
                }
            </div>
        );
    }
}


export default CustomSelect;