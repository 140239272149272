import axios from "axios";
import { api_url } from "../config";
import authHeader from "./auth-header";
import authService from "./auth.service";

const authAxios = axios.create({
  baseURL: api_url + "users",
  headers: authHeader(),
});

class UserService {
  constructor() {
    authAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (401 === error.response.status) {
          if (error.response.data.message === "code1") {
            localStorage.clear();
            window.location.href = '/login'
          } else if (error.response.data.message === "code2") {
            try {
              await authService.refresh();
              window.location.reload();
            } catch (error) {
              console.log(error)
            }
          } else if (error.response.data.message === "code3") {
            localStorage.clear();
            window.location.href = '/login'
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
  
  getUser() {
    return authAxios.get(`/authenticated-user`, { withCredentials: true });
  }

  choosePharmacy(data) {
    return authAxios.post(`/choose-pharmacy`, data, { withCredentials: true });
  }

}

export default new UserService();
