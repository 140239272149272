import React, { Component } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { FaEye } from 'react-icons/fa';
import { RiErrorWarningLine } from 'react-icons/ri';
import { ImLocation2 } from 'react-icons/im';
import { FaPhoneAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import Store from '../../Store/configStore';
import prescriptionsService from '../../services/prescriptions.service';
import './AddsComponent.css';

class AddsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalProducts: null,
            totalPatients: null,
            totalPrescriptions: null,
            country: null
        }
    }

    componentWillMount() {
        this.getPrescriptionsKeyFigures()
        this.setState({ country: Store?.getState()?.handleCurrentCountry?.currentCountry });
    }

    getPrescriptionsKeyFigures = async () => {
        try {
            let respose = await prescriptionsService.getPrescriptionsKeyFigures()
            console.log(respose);
            this.setState({ prescriptionData: respose.data }, () => console.log(this.state.prescriptionData))
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { prescriptionData, country } = this.state
        return (
            <>
                <div className="AddsComponent" id="dashboard_banners_container">
                    {/* <div className="banner">
                    < a href="/posts/index/follow-link/post_id/155" target="_blank" >
                        <img src="https://central.sobrus.com/uploads/ma/posts/1590154973_26015_126df01ba07ded3964223cecefe47c0075ec7d6dd25d2c.jpg" />
                    </a >
                </div > */}
                   {country !== "ma" && <div className="bande_dashBord">
                        <div className="totalItemsWidth">
                            <h2 className="totalePosologies">{prescriptionData?.newPrescriptions}</h2>
                        </div>
                        <div>
                            <h2 className="paragraph_totalePosologies">Nouvelles ordonnances</h2>
                        </div>
                    </div>}
                    {country !== "ma" && <div className="bande_dashBord">
                        <div className="totalItemsWidth">
                            <h2 className="totalepatients">{prescriptionData?.readyPrescriptions}</h2>
                        </div>
                        <div>
                            <h2 className="paragraph_totalePosologies">Ordonnances prêtes</h2>
                        </div>
                    </div>}
                   {country !== "ma" && <div className="bande_dashBord">
                        <div className="totalItemsWidth">
                            <h2 className="totaleProduits">{prescriptionData?.pendingPrescriptions}</h2>
                        </div>
                        <div>
                            <h2 className="paragraph_totalePosologies">Ordonnances en cours</h2>
                        </div>
                    </div>}
                    <div className="app_statistiques">
                        <div className="app_visiteres">
                            <FaEye className="app_visiteres_icon" />
                            <div>
                                <p>Visites</p>
                                <p>{prescriptionData?.consultations}</p>
                            </div>
                            <RiErrorWarningLine className="app_visiteres_warning_icon" data-tip="Le nombre de personnes ayant visitées votre pharmacie" />
                        </div>
                        <div className="app_visiteres_favorites">
                            <AiFillStar className="app_visiteres_icon" />
                            <div>
                                <p>Ajout aux favoris</p>
                                <p>{prescriptionData?.addedToFavorites}</p>
                            </div>
                            <RiErrorWarningLine className="app_visiteres_warning_icon" data-tip="Le nombre de personnes ayant ajoutées votre pharmacie en favoris" />
                        </div>
                    </div>
                    <div className="app_statistiques">
                        <div className="app_location">
                            <ImLocation2 className="app_visiteres_icon" />
                            <div>
                                <p>Clics sur itinéraire</p>
                                <p>{prescriptionData?.itineraryClicks}</p>
                            </div>
                            <RiErrorWarningLine className="app_visiteres_warning_icon" data-tip="Le nombre de personnes ayant cliquées pour afficher la géolocalisation de votre pharmacie" />
                        </div>
                        <div className="app_calls">
                            <FaPhoneAlt className="app_visiteres_icon" />
                            <div>
                                <p>Appels</p>
                                <p>{prescriptionData?.callClicks}</p>
                            </div>
                            <RiErrorWarningLine className="app_visiteres_warning_icon" data-tip="Le nombre de personnes ayant cliquées pour vous appeler ou pour afficher le numéro de votre pharmacie" />
                        </div>
                    </div>
                </div >
                <ReactTooltip />
            </>
        );
    }
}

export default AddsComponent;
