import React, { Component } from 'react';
import { MANAGE_PROFILE_URL } from '../../config';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import DefaultProfileIMG from '../../images/DefaultProfileIMG.png'
import userService from '../../services/user.service';
import './Account.css';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: null,
        };
    }

    componentDidMount() {
        document.title = "Paramètres - Sobrus Pharma";
        this.getUser();
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data })
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <>
                <Header />
                <div className="Account">
                    <TopOptions title="Paramètres">
                        <button onClick={() => window.open(MANAGE_PROFILE_URL, '_blank')} className="btn_bleu">Gérer mon compte Sobrus</button>
                    </TopOptions>
                    <div className="info_profile_and_sub_menu">
                        <SideMenu />
                        <div style={{ width: "74%" }}>
                            <div className="ParametresInfos">
                                <div className="profil_info_card_header">
                                    <h2>Profil</h2>
                                </div>
                                <div className="profil_info_card_bloc_content">
                                    <div className="param_user_guide">
                                        <p>Cette page contient vos informations et paramètres.</p>
                                    </div>
                                </div>
                                <div className="profilInfoCard">
                                    <div className="profil_info_card_header">
                                        <h2>Informations générales</h2>
                                    </div>
                                    <div className="profil_info_card_bloc_content">
                                        <div className="profil_info_card_wrapper">
                                            <em className="profil_info_card_thumb">
                                                <img src={DefaultProfileIMG} alt="DefaultProfileIMG" style={{ maxHeight: "100%!important", height: "auto!important", position: "relative", top: "50%", transform: "translateY(-50%)" }} />
                                            </em>
                                            <div className="profil_info_card_cnt">
                                                <div className="profil_info_card_left">
                                                    <div className="profil_info_card_row">
                                                        <span>Nom du compte</span>
                                                        <p>{this.state.userData
                                                            ? this.state.userData.firstName + " " + this.state.userData.lastName
                                                            : "Chargement en cours..."}</p>
                                                    </div>
                                                </div>
                                                <div className="profil_info_card_right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </>
        );
    }
}

export default Account;
