import React from "react";
import whiteArrow from "../assets/white-arrow.svg";
import imgMobile from "../assets/img-mobile.svg";
import { Link } from "react-scroll";

function Section2() {
    return (
        <section className="section-2" id="section2">
            <div className="container row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12" data-aos='fade-right'>
                    <div className="ImgContent">
                        <img src={imgMobile} alt="" className="img-fluid" width="80%" />
                    </div>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content-s-2" data-aos="fade-left">
                    <h3 className="h3">- Présentation</h3>
                    <h1 className="h1">
                        Le module de <br /> <strong>Click & Collect * ?</strong>
                    </h1>
                    <p className="p">
                        Le module de <strong>Click & Collect</strong> de{" "}
                        <strong>Sobrus Pharma</strong> vous permet de recevoir et de{" "}
                        <strong>gérer les ordonnances de vos patients à l’avance</strong>.
                        <br />
                        <br />
                        Une fois que la commande est prête,{" "}
                        <strong>
                            le patient est notifié automatiquement pour récupérer ses médicaments en
                            pharmacie
                        </strong>
                        .
                    </p>
                    <br />
                    <p className="p p_lil">* Ce service est disponible exclusivement en France & en Belgique.</p>
                    <br />
                    <Link to="compte" spy={true} smooth={true} offset={-200} duration={500}>
                        <button className="btn">
                            Demander un compte gratuit
                            <img src={whiteArrow} alt="" />
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Section2;
