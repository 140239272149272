const initialState = { currentCountry: "ma" }

function handleCurrentCountry(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "HANDLE_CURRENT_COUNTRY":
            nextState = {
                ...state,
                currentCountry: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}
export default handleCurrentCountry;