import React from "react";
import { Link } from "react-scroll";
import MockUpWhite2x from "../assets/macbook-white@2x.png";
import whiteArrow from "../assets/white-arrow.svg";

function Section6() {
    return (
        <section className="section-6 col-12" id="section6">
            <div className="container row ">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content" data-aos='fade-right'>
                    <h1 className="h1">
                        Gagnez en <br /> <strong>visibilité sur le web</strong>
                    </h1>
                    <p className="p">
                        grâce à notre <strong>annuaire interactif de pharmacies</strong>,
                        MonPharmacien !
                    </p>

                    <br />
                    <Link to="compte" spy={true} smooth={true} offset={-200} duration={500}>
                        <button className="btn btn-customize">
                            Demander un compte gratuit
                            <img src={whiteArrow} alt="" />
                        </button>
                    </Link>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 content" data-aos="fade-left">
                    <div className="ImgContent">
                        <img
                            srcSet={`${MockUpWhite2x}, ${MockUpWhite2x} 2x`}
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section6;
