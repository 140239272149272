import React from "react";
import fb from "../assets/fb-icon.svg";
import linkedin from "../assets/linkedin-icon.svg";

const HeaderLinks = () => {
    return (
        <div className="header-links">
            <div className="header-container container">
                <p>Besoin d’aide ? Appelez-nous au +33 3 62 27 90 70</p>
                <div className="social-links">
                    <a className="a"
                        href="https://www.facebook.com/MonPharmacienSobrus"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={fb} alt="" />
                    </a>
                    <a className="a"
                        href="https://www.linkedin.com/showcase/monpharmacien/about/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={linkedin} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HeaderLinks;
