import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import moment from "moment";
import "./InfoCard.css";

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataIsLoaded: false,
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
        if (prevProps.tbody.dataIsLoaded !== this.props.tbody.dataIsLoaded) {
            this.setState((prevState) => ({
                ...prevState,
                tbody: {
                    ...prevState.tbody,
                    dataIsLoaded: this.props.tbody.dataIsLoaded,
                },
            }));
        }
    }

    getFormateData = (object, item) => {
        if (item?.property === "actionDate" || item?.property === "invoiceDate") {
            return moment(object[item?.property]).format("YYYY-MM-DD à HH:mm")
        }
        
        if (item?.property === "status") {
            return <p>{object[item?.property]}</p>
        }
        
        if (item?.property === 'updatedAt') {
            return <p>{moment(object[item?.property]).format("YYYY-MM-DD à HH:mm")}</p>
        }

        if (item?.property === "patient") {
            return <p>{object[item?.property]?.firstName}</p>
        } else if (item?.property === "actionBy") {
            return <p>{object[item?.property]?.firstName}</p>
        } else {
            return object[item?.property]
        }
        
    }

    render() {
        const { title, thead, tbody, tfoot } = this.props;
        return (
            <div className="InfoCard" style={title ? {} : { marginTop: "0px" }}>
                {
                    title ? <div className="bloc_header">
                        <h2>{title}</h2>
                    </div> : ""
                }

                <div className="bloc_content" style={{ padding: "0px" }}>
                    <table className="table processableTable">
                        <thead>
                            <tr className="table_th_orderable">
                                {thead &&
                                    thead.map((item, index) => {
                                        return <th className="TH" key={"key_TH_InfoCard_" + index}>{item.label}</th>;
                                    })}
                                <th className="table_actions TH" />
                            </tr>
                        </thead>
                        {tbody && !tbody.dataIsLoaded && (
                            <tbody>{<Loading columns={thead} />}</tbody>
                        )}
                        {tbody && tbody.dataIsLoaded && (
                            <tbody>
                                {tbody && tbody.data.map((object, index) => {
                                    return (
                                        <tr className="TR" key={"key_TR_InfoCard_" + index} >
                                            {
                                                thead && thead.map((item, index) => (
                                                    <td className="TD" key={"key_TD_InfoCard_" + index}>{this.getFormateData(object, item)}</td>
                                                ))
                                            }
                                            <td className="table_action TD">{this.props.children}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>)
                        }
                         {tfoot &&
                            <tbody>
                                <tr className="pagination_tr">
                                    <td colSpan={tfoot.colSpan} className="table_pagination">
                                        <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                        <div style={{ display: "inline-block" }}>
                                            {tfoot.pageNumber > 1 && <a className="sprite-table-prev-btn" onClick={() => tfoot.PrevPage()} />}
                                            <span className="table_page">{tfoot.pageNumber}</span>
                                            {tfoot.pageNumber < tfoot.totalItems / 5 && <a className="sprite-table-next-btn" onClick={() => tfoot.nextPage()} />}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            // <TablePagination
                            //     colSpan={tfoot.colSpan}
                            //     pageNumber={tfoot.pageNumber}
                            //     totalItems={tfoot.totalItems}
                            //     onPrevBtnClick={() => tfoot.PrevPage()}
                            //     onNextBtnClick={() => tfoot.nextPage()}
                            // />
                        }
                    </table>
                </div>
            </div>
        );
    }
}

export default InfoCard;

const Loading = (props) => {
    const { columns } = props;
    return (
        <>
            {[1, 2, 3].map((index) => {
                return (
                    <tr className="TR" key={"key__" + index}>
                        {columns.map(() => (
                            <td className="TD">
                                <ContentLoader width="160" height="20">
                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                </ContentLoader>
                            </td>
                        ))}
                    </tr>
                );
            })}
        </>
    );
};
