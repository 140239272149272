import React, { Component } from 'react';
import PharmaLogo from "../../images/pharmaLogo.png";
import Switch from '../../images/switch.svg';
import Flag_of_France from '../../images/Flag_of_France.png';
import Flag_of_Maroc from '../../images/Flag_of_Maroc.png';
import Fenegale_flag from '../../images/Fenegale_flag.png';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';
import { withRouter } from 'react-router-dom';
import { LOGIN_PHARMACIST } from '../../config'
import './ChoiceCountries.css';

class ChoiceCountries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacieData: [],
            slugs: []

        }
    }

    componentDidMount() {
        const state = this.props.location.state;
        if (state !== undefined) {
            const slugs = state.successMessage.split(',')
            this.setState({ slugs })
            slugs.map((item) => {
                this.getPharmacieByslug(item)
            })
        } else {
            this.props.history.push('/')
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ pharmacieData: [...this.state.pharmacieData, response.data.pharmacy] })
        } catch (error) {
            console.log(error);
        }
    }

    choosePharmacy = async (country) => {
        try {
            const data = {
                country: country.toLowerCase()
            }
            await userService.choosePharmacy(data)
            this.props.history.push('/auth/success')
        } catch (error) {
            console.log(error);
        }
    }

    scrollCourseCardRight = () => {
        document.getElementById('courseCard_group').scrollLeft += 320;
    };

    render() {
        const { slugs, pharmacieData } = this.state
        return (
            <div className="ChoiceCountries">
                <div className="ChoiceCountries_left">
                    <div>
                        <img src={PharmaLogo} alt="logo" />
                    </div>
                    <p className="ChoiceCountries_left_title">Choisissez votre compte</p>
                    <div className="container_with_arrow">
                        <div className='pharmacy_cards_container' id="courseCard_group">
                            {
                                pharmacieData.map((item, key) => {
                                    return <div className="pharmacy_card" key={key} onClick={() => this.choosePharmacy(item?.country)}>
                                        <div className="pharmacy_card_image_container">
                                            <img src={PharmaLogo} alt="logo" style={{ marginTop: "5px" }} />
                                        </div>
                                        <p className="pharmacy_card_name">{item?.name}</p>

                                        <img className='pharmacy_card_image' src={item?.country === "FR" ? Flag_of_France : item?.country === "MA" ? Flag_of_Maroc : item?.country === "SN" ? Fenegale_flag : ''} alt="logo" />
                                    </div>
                                })
                            }

                            {/* <div className="pharmacy_card">
                                <div className="pharmacy_card_image_container">
                                    <img src={PharmaLogo} alt="logo" />
                                </div>
                                <p className="pharmacy_card_name">Pharmacie Paris</p>
                                <img src={Flag_of_France} alt="logo" />
                            </div> */}
                        </div>
                        {
                            slugs.length > 2 && <div className="CoursesList_icons">
                                <i className="fas fa-chevron-right accueil_sixth_part_icon" onClick={() => this.scrollCourseCardRight()} ></i>
                            </div>
                        }
                    </div>

                    <a className="ChoiceCountries_left_login_link" onClick={() => window.open(LOGIN_PHARMACIST)}>Se connecter à un autre compte existant.</a>
                </div>
                <div className="ChoiceCountries_right">
                    <img src={Switch} className="ChoiceCountries_image" alt="logo" />
                    <p className="ChoiceCountries_right_title">Tous les services Sobrus <br /> avec <strong>un seul compte</strong></p>
                </div>
            </div>
        );
    }
}

export default withRouter(ChoiceCountries);
