import axios from "axios";
import { api_url, TOKEN_URL } from "../config";

const API_URL = api_url;

class AuthService {
  constructor() {
    this.authenticated = false;
  }

  login = (cb) => {
    localStorage.setItem("isLogged", true);
    this.authenticated = true;
    cb();
  };

  logout = () => {
    localStorage.clear();
    this.authenticated = false;
    window.location.href = api_url.substr(0, api_url.length - 4) + "auth/revoke";
  };

  refresh = async () => {
    return axios.get(TOKEN_URL.substr(0, api_url.length - 4) + `auth/refresh`, { withCredentials: true });
  };

  isAuthenticated = () => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged) {
      return true;
    }
    return false
  };

}

export default new AuthService();
