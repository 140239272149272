import React from "react";

const Article = ({ title, body, hasBG = false }) => {
    return (
        <div className={`article-wrapper ${hasBG ? "bg" : ""}`}>
            <div className="container mx-auto">
                <h4 className="h4">{title}</h4>
                <div>{body}</div>
            </div>
        </div>
    );
};

export default Article;
