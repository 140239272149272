import React, { Component } from 'react';
import './CommentPopup.css';
import { withRouter } from 'react-router';
import { GrClose } from 'react-icons/gr';

class CommentPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            is_loaded: false,
            cancelationRaison: this.props.selectedId,
            cancelationRaisonName: "",
            showCommentPopup: false,
        };

    }
    componentDidMount() {
        ;
    }

    toggleCommentPopup() {
        this.setState({
            showCommentPopup: !this.state.showCommentPopup
        });
    }

    handleChange = (e) => {
        this.setState({ comment: e.target.value });
    };

    selectchangeStatusHandler() {
        this.props.getData(this.state.comment);
        this.props.closePopup()
    }


    render() {
        const { data, currentPage, totalItems, is_loaded } = this.state

        return (
            <>
                <div className="PatientsPopup">
                    <div id="popup_container" ref={this.props.reference} className="popup_cnt ui-draggable" style={{ width: "600px" }}>
                        <div className="Popup_header">
                            <p>Liste des raisons d'annulation</p>
                            <GrClose className="fas fa-times Popup_header_close" onClick={this.props.closePopup} />
                        </div>
                        <div id="popup_content" className="popup_content">
                            <div className="facture_total_terms background_color_for_containers">
                                <div className="ParametresInfosEdit">
                                    <div className="">
                                        <h2>commentaire</h2>
                                    </div>
                                    <div className="texterea_container">
                                        <textarea name="terms_and_conditions" id="terms_and_conditions" rows="5" cols="33" className="textarea" value={this.state.comment} onChange={this.handleChange} ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="PatientsPopup_footer">
                            <button className="btn_bleu popup_close" onClick={() => this.selectchangeStatusHandler()}>Ajouter</button>
                            <button className="btn_green popup_close" onClick={this.props.closePopup}>Annuler</button>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default withRouter(CommentPopup);
