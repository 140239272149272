import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import Dropzone from 'react-dropzone';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';
import './PharmacyImages.css';
import Loader from '../../Helpers/Loader';

class PharmacyImages extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            this.setState({ files }, () => console.log(this.state.files));
        };
        this.onDropPrinc = (filePrinc) => {
            if (this.state.filePrincToUpdate.length !== 0) {
                const x = this.state.filePrincToUpdate.concat(filePrinc)
                this.setState({ filePrincToUpdate: [...x] }, () => console.log(this.state.filePrincToUpdate));
            } else {
                this.setState({ filePrinc }, () => console.log(this.state.filePrinc));
            }
        };
        this.onDropPharmacistPicture = (pharmacistPicture) => {
            this.setState({ pharmacistPicture }, () => console.log(this.state.pharmacistPicture));
        };

        this.state = {
            fields: {
                ...this.fieldsData
            },
            files: [],
            fileToUpdate: null,
            imgError: null,
            filePrinc: [],
            picturesToUpdate: null,
            filePrincToUpdate: [],
            imagesToUpdate: [],
            pharmacistPicture: [],
            pharmacistPictureToupdate: [],
            pharmacieData: null,
            loader: true,
            noFav: false
        }
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data, slug: response.data.pharmacySlug }, () => this.getPharmacieByslug())
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async () => {
        const { slug } = this.state
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            const filePrincToUpdate = []
            response.data.pharmacy?.pictures.map(async (item, key) => {
                if (item?.pictureType === "pharmacy") {
                    const response = await fetch(`https://api.monpharmacien.sobrus.ovh/images/pharmacies/${item?.pictureRef}`);
                    // here image is url/location of image
                    const blob = await response.blob();
                    const file = new File([blob], { type: blob.type });
                    filePrincToUpdate.push(file)
                    this.setState({ filePrincToUpdate })
                } else if (item?.pictureType === "cover") {
                    this.setState({ fileToUpdate: item })
                } else if (item?.pictureType === "pharmacist") {
                    this.setState({ pharmacistPictureToupdate: item })
                }
            })
            this.setState({ loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    updatePharmacyImages = async () => {
        const { files, filePrinc, pharmacistPicture, filePrincToUpdate } = this.state
        try {
            let formData = new FormData();
            if (filePrincToUpdate.length !== 0) {
                filePrincToUpdate.forEach(filePrinToUp => {
                    formData.append('pictures[]', filePrinToUp);
                });
            } else {
                filePrinc.forEach(filePrin => {
                    formData.append('pictures[]', filePrin);
                });
            }
            formData.append('coverPicture', files[0]);
            formData.append('pharmacistPicture', pharmacistPicture[0]);

            await pharmaciesServices.updatePharmacyImages(formData)
            this.setState({ noFav: true }, () => {
                window.scrollTo(0, 0)
                this.getPharmacieByslug()
                this.emailNotefitacion()
            })
        } catch (error) {
            console.log(error);
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    imageUrlToFile = async (url, name) => {
        let originalName = name.slice(0, name.lastIndexOf("-"));
        let originalExtension = name.slice(name.lastIndexOf("."));
        let fileName = originalName + originalExtension;
        const response = await fetch(url);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: blob.type });
        let initialImageFiles = [];
        initialImageFiles.push(file);
        this.setState({ initialImageFiles: initialImageFiles });
        // return file;
    };

    render() {
        const { files, imgError, filePrincToUpdate, filePrinc, pharmacistPicture, pharmacistPictureToupdate, fileToUpdate, loader, noFav } = this.state;
        return (
            <div className="OrdonnancesTable_container" >
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                        <i className="far fa-check-circle"></i>
                        <p>Vos photos ont bien été modifiées.</p>
                    </div>
                }
                <Header />
                <TopOptions title="Photos de la pharmacie">
                    <button id="printer" className="btn_bleu" onClick={() => this.updatePharmacyImages()}>Enregistrer</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Photos de la pharmacie</h2>
                            </div>
                            <div className="bloc_content">
                                <div>
                                    <div className="form_row">
                                        <div className="bloc_h3">
                                            <span style={{ width: "100%", left: 0 }} ></span>
                                            <h3>Photo de couverture :</h3>
                                        </div>
                                        <Dropzone onDrop={this.onDrop}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container" style={{ width: '100%', padding: '0px' }}>
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input type="file" {...getInputProps()} />
                                                        <p style={{ textAlign: 'center' }}>Choisissez une photo de couverture</p>
                                                    </div>
                                                    <aside>
                                                        <ul>
                                                            < div className="thumb" key={files[0]?.name} >
                                                                <div className="thumbInner">
                                                                    {
                                                                        files.length === 0 && fileToUpdate ?
                                                                            <img className="thumbInner_img" src={`https://api.monpharmacien.sobrus.ovh/images/pharmacies/${fileToUpdate?.pictureRef}`} /> :
                                                                            <img className="thumbInner_img" src={files.length !== 0 && URL.createObjectURL(files[0])} />
                                                                    }
                                                                </div>
                                                            </div >
                                                        </ul>
                                                        {imgError ? <p className="erreurInputText"> <><i className="far fa-times-circle errorIcon"></i> {imgError}</> </p> : ""}
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className="form_row">
                                        <div className="bloc_h3">
                                            <span style={{ width: "100%", left: 0 }} ></span>
                                            <h3>Photo du pharmacien :</h3>
                                        </div>
                                        <Dropzone onDrop={this.onDropPharmacistPicture}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section className="container" style={{ width: '100%', padding: '0px' }}>
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input type="file" {...getInputProps()} />
                                                        <p style={{ textAlign: 'center' }}>Choisissez une photo du pharmcien</p>
                                                    </div>
                                                    <aside>
                                                        <ul>
                                                            < div className="thumb" key={pharmacistPicture[0]?.name} >
                                                                <div className="thumbInner">
                                                                    {
                                                                        pharmacistPicture.length === 0 && pharmacistPictureToupdate ?
                                                                            <img className="thumbInner_img" src={`https://api.monpharmacien.sobrus.ovh/images/pharmacies/${pharmacistPictureToupdate?.pictureRef}`} /> :
                                                                            <img className="thumbInner_img" src={pharmacistPicture?.length !== 0 && URL.createObjectURL(pharmacistPicture[0])} />
                                                                    }
                                                                </div>
                                                            </div >
                                                        </ul>
                                                        {imgError ? <p className="erreurInputText"> <><i className="far fa-times-circle errorIcon"></i> {imgError}</> </p> : ""}
                                                    </aside>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className="form_row">
                                        <div className="bloc_h3">
                                            <span style={{ width: "100%", left: 0 }} ></span>
                                            <h3>Photos de la pharmacie :</h3>
                                        </div>
                                        <div >
                                            <Dropzone onDrop={this.onDropPrinc} multiple={true} >
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className="container" style={{ width: '100%', padding: '0px', backgroundColor: "#dedede", borderRadius: "3px", overflow: "overlay" }}>
                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                            <input type="file" {...getInputProps()} />
                                                            <p style={{ textAlign: 'center' }}>Choisissez les photos de la pharmcies</p>
                                                        </div>
                                                        <aside>
                                                            <ul>
                                                                < div className="thumb" >
                                                                    <div className="thumbInner">
                                                                        {
                                                                            filePrincToUpdate && filePrinc.length === 0 ?
                                                                                filePrincToUpdate.length !== 0 && filePrincToUpdate.map((itme, key) => {
                                                                                    return <div className="filePrincToUpdate">
                                                                                        <i className="fas fa-times filePrincToUpdate_icon" onClick={() => {
                                                                                            const index = this.state.filePrincToUpdate.indexOf(itme);
                                                                                            if (index > -1) {
                                                                                                this.state.filePrincToUpdate.splice(index, 1);
                                                                                                this.setState({ filePrincToUpdate: [...this.state.filePrincToUpdate] })
                                                                                            }
                                                                                        }} ></i>
                                                                                        <img className="thumbInner_img" src={itme?.pictureRef ? `https://api.monpharmacien.sobrus.ovh/images/pharmacies/${itme?.pictureRef}` : URL.createObjectURL(itme)} />
                                                                                    </div>
                                                                                }) : filePrinc.length !== 0 && filePrinc.map((filePrinc, key) => {
                                                                                    return <img key={key} className="thumbInner_img" src={filePrinc.length !== 0 && URL.createObjectURL(filePrinc)} />
                                                                                })
                                                                        }
                                                                    </div>
                                                                </div >
                                                            </ul>
                                                            {imgError ? <p className="erreurInputText"> <><i className="far fa-times-circle errorIcon"></i> {imgError}</> </p> : ""}
                                                        </aside>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyImages;
