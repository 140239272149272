var api_url = "https://api.monpharmacien.sobrus.ovh/api/";
let MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
let LOGIN_PHARMACIST = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Ftoken%26prompt%3Dlogin";
let TOKEN_URL = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Ftoken%26prompt%3Dlogin";
let PIC_URL = "https://cnc.sobrus.ovh/uploaded_files/prescriptions";
let MONPHARMACIEN_BASE_URL = "https://monpharmacien.sobrus.ovh/pharmacie/"

if (process.env.REACT_APP_ENV === "development") {
    api_url = "https://api.monpharmacien.sobrus.ovh/api/";
    MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
    LOGIN_PHARMACIST = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Ftoken%26prompt%3Dlogin";
    TOKEN_URL = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Ftoken%26prompt%3Dlogin";
    PIC_URL = "https://cnc.sobrus.ovh/uploaded_files/prescriptions"
    MONPHARMACIEN_BASE_URL = "https://monpharmacien.sobrus.ovh/pharmacie/"
}

if (process.env.REACT_APP_ENV === "production") {
    api_url = "https://api.monpharmacien.sobrus.com/api/";
    MANAGE_PROFILE_URL = "https://account.sobrus.com/account";
    LOGIN_PHARMACIST = "https://account.sobrus.com/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.com%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.com%2Fauth%2Ftoken%26prompt%3Dlogin";
    TOKEN_URL = "https://account.sobrus.com/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.com%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.xom%2Fauth%2Ftoken%26prompt%3Dlogin";
    PIC_URL = "https://cnc.sobrus.com/uploaded_files/prescriptions"
    MONPHARMACIEN_BASE_URL = "https://monpharmacien.sobrus.com/pharmacie/"
}

export { api_url, MANAGE_PROFILE_URL, TOKEN_URL, PIC_URL, LOGIN_PHARMACIST, MONPHARMACIEN_BASE_URL };
