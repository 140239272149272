import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

const openingHoursOfnull = [{ id: 1, dayOfWeek: 0, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 2, dayOfWeek: 1, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 3, dayOfWeek: 2, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 4, dayOfWeek: 3, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 5, dayOfWeek: 4, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 6, dayOfWeek: 5, startOne: "09:00", endOne: "13:00" }]

class PharmacyTimetableEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tEstablishmentParams: null,
            userData: null,
            openingHours: [],
            slug: null,
            timeingArray: [],
            pharmacyData: null,
            loader: true
        }
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ slug: response.data.pharmacySlug }, () => this.getPharmacieByslug())
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async () => {
        const { slug } = this.state
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ pharmacyData: response.data.pharmacy, openingHours: response.data.pharmacy?.openingHours.length !== 0 ? response.data.pharmacy?.openingHours : openingHoursOfnull, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    suggestDataPharmacy = async () => {
        try {
            const data = {
                days: this.state.timeingArray,
            }
            await pharmaciesServices.updatePharmacyOpningHours(this.state.pharmacyData.id, data)
            this.props.history.push('/account/pharmacy_timetable', {
                successMessage: true,
            })
        } catch (error) {
            console.log(error);
        }
    }

    preparHoursData = () => {
        const elements = document.querySelectorAll(".openingHours");
        let timeingArray = [];

        elements.length !== 0 && elements?.forEach((item, index) => {
            let timingObjet = {}
            document.querySelectorAll(`#openingHours${index} input`)?.forEach((X) => {
                timingObjet = {
                    ...timingObjet,
                    dayOfWeek: index,
                    [X.name]: X?.value?.substring(0, 5)
                }
            })
            timeingArray.push(timingObjet)
        })
        this.setState({ timeingArray }, () => this.suggestDataPharmacy())
    }

    render() {
        const { openingHours, loader } = this.state;
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                <Header />
                <div className="Account">
                    <TopOptions title="Horaires d'ouverture de la pharmacie">
                        <button onClick={() => this.preparHoursData()} className="btn_bleu">Sauvegarder</button>
                    </TopOptions>
                    <div className="info_profile_and_sub_menu">
                        <SideMenu />
                        <div style={{ width: "74%" }}>
                            <div className="ParametresInfos">
                                <div className="profilInfoCard">
                                    <div className="profil_info_card_header">
                                        <h2>Modifier les horaires d’ouverture de la pharmacie</h2>
                                    </div>
                                    {
                                        openingHours && openingHours.map((item, index) => {
                                            return <div className="bloc_content" key={item.id}>
                                                <div className="bloc_h3">
                                                    <span style={{ width: "100%", left: 0 }} ></span>
                                                    <h3>{item.dayOfWeek === 0 ? "Lundi" :
                                                        item.dayOfWeek === 1 ? "Mardi" :
                                                            item.dayOfWeek === 2 ? "Mercredi" :
                                                                item.dayOfWeek === 3 ? "Jeudi" :
                                                                    item.dayOfWeek === 4 ? "Vendredi" :
                                                                        item.dayOfWeek === 5 ? "Samedi" :
                                                                            item.dayOfWeek === 6 ? "Dimanche" : ""}</h3>
                                                </div>
                                                <div className="bloc_content openingHours" id={`openingHours${index}`}>
                                                    <div className="ParametresGrid">
                                                        <div className="ParametresGridDetail">
                                                            <div className="form_row">
                                                                <label htmlFor="accountName">De :</label>
                                                                <input placeholder="" type="houre" name="startOne" className="first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" defaultValue={item.startOne} />
                                                            </div>
                                                        </div>
                                                        <div className="ParametresGridDetail">
                                                            <div className="form_row">
                                                                <label htmlFor="accountName">à :</label>
                                                                <input placeholder="" type="houre" name="endOne" className="first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" defaultValue={item.endOne} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ParametresGrid">
                                                        {item.startTwo && <div className="ParametresGridDetail">
                                                            <div className="form_row">
                                                                <label htmlFor="accountName">De :</label>
                                                                <input placeholder="" type="houre" name="startTwo" className="first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" defaultValue={item.startTwo} />
                                                            </div>
                                                        </div>}
                                                        {item.endTwo && <div className="ParametresGridDetail">
                                                            <div className="form_row">
                                                                <label htmlFor="accountName">à :</label>
                                                                <input placeholder="" type="houre" name="endTwo" className="first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" defaultValue={item.endTwo} />
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyTimetableEdit;
