import React from "react";
import Article from "./Article";
import PageHeader from "./PageHeader";

const Politiques = () => {
    return (
        <div className="Politiques">
            <PageHeader title="Politique de confidentialité" />
            <Article
                title="A. Introduction"
                body={
                    <>
                        <p className="p">
                            La confidentialité des visiteurs de notre site web est très importante
                            pour Sobrus Pharma, nous nous engageons à la protéger. Cette politique
                            détaille ce que nous faisons de vos informations personnelles.
                        </p>
                        <p className="p">
                            Consentir à notre utilisation de cookies en accord avec cette politique
                            lors de votre première visite à notre site web nous permet d’utiliser
                            des cookies à chaque fois que vous le consultez.{" "}
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="B. Les données que nous recueillons"
                body={
                    <>
                        <p className="p">
                            Après votre consentement, les informations suivantes peuvent être
                            collectés, stockés et utilisés :
                        </p>
                        <ol>
                            <li>
                                Votre nom et prénom, votre numéro de téléphone, votre spécialité,
                                votre adresse ;
                            </li>
                            <li>
                                Votre adresse e-mail, que vous nous fournissez lors de votre
                                inscription au site ;
                            </li>
                            <li>
                                Le type de votre ordinateur, y compris votre adresse IP, votre
                                localisation géographique, le type et la version de votre
                                navigateur, et votre système d’exploitation ;
                            </li>
                            <li>
                                Vos visites et votre utilisation de ce site web y compris la source
                                référente, la durée de la visite, les pages vues et les chemins de
                                navigation de site web ;
                            </li>
                            <li>
                                Des informations générées lors de l’utilisation de notre site, y
                                compris quand, à quelle fréquence et sous quelles circonstances vous
                                l’utilisez ;
                            </li>
                            <li>
                                Le contenu des communications que vous nous envoyez par e-mail ou
                                sur notre site web, y compris leurs métadonnées ;
                            </li>
                            <li>Toute autre information personnelle que vous nous communiquez.</li>
                        </ol>
                        <p className="p">
                            Avant de nous divulguer des informations personnelles concernant une
                            autre personne, vous devez obtenir le consentement de ladite personne en
                            ce qui concerne la divulgation et le traitement de ses informations
                            personnelles selon les termes de notre politique.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="C. Utilisation de vos données personnelles"
                body={
                    <>
                        <p className="p">
                            Les données personnelles que nous accueillerons par notre site web
                            seront utilisées dans les objectifs décrits dans cette politique. Nous
                            pouvons les utiliser pour :
                        </p>
                        <ol>
                            <li>Administrer notre site web et notre entreprise ;</li>
                            <li>Personnaliser notre site web pour vous ;</li>
                            <li>
                                Permettre votre utilisation des services proposés sur notre outil ;
                            </li>
                            <li>
                                Vous envoyer des campagnes marketing relatives à notre entreprise ou
                                à des entreprises tierces sélectionnées avec soin qui selon nous
                                pourraient vous intéresser, sous forme de publication, ou si vous
                                avez expressément donné votre accord, par e-mail ou technologie
                                similaire (vous pouvez nous informer à tout moment de votre volonté
                                de ne plus recevoir de campagnes marketing) ;
                            </li>
                            <li>
                                Vous envoyer des communications commerciales non-relatives au
                                marketing ;
                            </li>
                            <li>
                                Vous envoyer des notifications par e-mail que vous avez expressément
                                demandées ;
                            </li>
                            <li>
                                Vous envoyer notre newsletter par mail (vous pouvez nous informer à
                                tout moment de votre volonté de ne plus recevoir notre newsletter) ;
                            </li>
                            <li>
                                Fournir des informations statistiques anonymes à propos de nos
                                utilisateurs à des tierces parties (sans que ces tierces parties
                                puissent identifier d’utilisateur individuel avec ces informations)
                                ;
                            </li>
                            <li>
                                Traiter les demandes et les réclamations relatives à votre site web
                                effectuées par vous ou vous concernant ;
                            </li>
                            <li>Maintenir la sécurité de notre site web et empêcher la fraude ;</li>
                            <li>
                                Vérifier le respect des conditions générales qui régissent
                                l’utilisation de notre site web ;
                            </li>
                        </ol>
                        <p className="p">
                            Si vous soumettez des informations personnelles sur notre site web dans
                            le but de les publier, nous les publierons et pourrons utiliser ces
                            informations conformément aux autorisations que vous nous accordez.
                        </p>
                        <p className="p">
                            Vos paramètres de confidentialité peuvent être ajustés et utilisés pour
                            limiter la publication de vos informations sur notre site web.
                        </p>
                        <p className="p">
                            Sans votre consentement explicite, nous ne fournirons pas vos
                            informations personnelles à des tierces parties pour leur marketing
                            direct, ni à celui d’autres tierces parties.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="D. Divulgation de vos données personnelles"
                body={
                    <>
                        <p className="p">
                            Nous pouvons divulguer vos informations personnelles à n’importe lequel
                            de nos employés, dirigeants, assureurs, conseillers professionnels,
                            agents, fournisseurs ou sous-traitants dans la mesure où cela est
                            raisonnablement nécessaire aux fins énoncées dans cette politique.
                        </p>
                        <p className="p">Nous pouvons divulguer vos informations personnelles :</p>
                        <ol>
                            <li>Dans la mesure où nous sommes tenus de le faire par la loi ;</li>
                            <li>
                                Dans le cadre de toute procédure judiciaire en cours ou à venir ;
                            </li>
                            <li>
                                Pour établir, exercer ou défendre nos droits légaux (y compris
                                fournir des informations à d’autres à des fins de prévention des
                                fraudes et de réduction des risques de crédit) ;
                            </li>
                            <li>
                                À l’acheteur (ou acheteur potentiel) de toute entreprise ou actif en
                                notre possession que nous souhaitons (ou envisageons de) vendre ; et
                                à toute personne que nous estimons raisonnablement faire partie
                                intégrante d’un tribunal ou autre autorité compétente pour la
                                divulgation de ces informations personnelles si, selon notre
                                opinion, un tel tribunal ou une telle autorité serait susceptible de
                                demander la divulgation de ces informations.
                            </li>
                        </ol>
                        <p className="p">
                            Sauf disposition contraire de la présente politique, nous ne
                            transmettrons pas vos informations personnelles à des tierces parties.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="E. Transferts internationaux des données"
                body={
                    <>
                        <ol>
                            <li>
                                Les informations que nous collectons peuvent être stockées, traitées
                                et transférées dans tous les pays où nous opérons et les utiliser en
                                accord avec cette politique.
                            </li>
                            <li>
                                Les informations que nous collectons peuvent aussi être transférées
                                dans les pays suivants : “les États-Unis d’Amérique, la Russie, le
                                Japon, la Chine et l’Inde” où les lois de protection des données
                                n’est pas équivalentes à celles en vigueur .
                            </li>
                        </ol>
                        <p className="p">
                            Vous acceptez expressément le transfert d’informations personnelles
                            décrit dans cette section.
                        </p>
                    </>
                }
                hasBG={true}
            />
        </div>
    );
};

export default Politiques;
