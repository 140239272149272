import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ChoiceCountriesGetData extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getParamsFromURL()
    }

    getParamsFromURL = () => {
        const params = new URL(window.location.href).searchParams.get("slugs");
        this.props.history.push('/auth/switch_accounts', {
            successMessage: params,
        })
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default withRouter(ChoiceCountriesGetData);
