import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({ title }) => {
    return (
        <div className="pageHeader-wrapper">
            <div className="container mx-auto">
                <div>
                    <Link to="/">Accueil</Link>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <p className="p">{title}</p>
                </div>
                <h1 className="h1">{title}</h1>
            </div>
        </div>
    );
};

export default PageHeader;
