import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import InfoCard from '../../Helpers/InfoCard/InfoCard';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

const thead = [
    { property: "dayOfWeek", label: "Jour", type: "string" },
    { property: "Houre", label: "Heures d’ouverture", type: "string" }
]

const openingHoursOfnull = [{ id: 1, dayOfWeek: 0, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 2, dayOfWeek: 1, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 3, dayOfWeek: 2, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 4, dayOfWeek: 3, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 5, dayOfWeek: 4, startOne: "09:00", endOne: "12:30", startTwo: "15:00", endTwo: "19:30" },
{ id: 6, dayOfWeek: 5, startOne: "09:00", endOne: "13:00" }]

class PharmacyTimetable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tEstablishmentParams: null,
            userData: null,
            openingHours: [],
            slug: null,
            is_loaded: false,
            noFav: false,
            loader: true
        }
    }

    componentDidMount() {
        this.getUser();
        const state = this.props.location.state;
        if (state !== undefined) {
            if (state.successMessage) {
                this.setState({ noFav: true })
                this.emailNotefitacion();
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ slug: response.data.pharmacySlug }, () => this.getPharmacieByslug())
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async () => {
        const { slug } = this.state
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ is_loaded: true, openingHours: response.data.pharmacy?.openingHours.length !== 0 ? response.data.pharmacy?.openingHours : openingHoursOfnull }, () => this.prepareData(this.state.openingHours))
        } catch (error) {
            console.log(error);
        }
    }

    prepareData = (data) => {
        console.log(data);
        let openingHours = []

        data.forEach(el => {
            let HistoryCon = {
                dayOfWeek: el.dayOfWeek === 0 ? "Lundi" : el.dayOfWeek === 1 ? "Mardi" : el.dayOfWeek === 2 ? "Mercredi" : el.dayOfWeek === 3 ? "Jeudi" : el.dayOfWeek === 4 ? "Vendredi" : el.dayOfWeek === 5 ? "Samedi" : "",
                Houre: el ? (el?.startOne ?? "") + " " + (el?.endOne ?? "") + " | " + (el?.startTwo ?? "") + " " + (el?.endTwo ?? "") : ""
            }
            openingHours.push(HistoryCon)
        });
        this.setState({ openingHours, loader: false }, () => console.log(this.state.openingHours))
    }


    render() {
        const { openingHours, is_loaded, loader, noFav } = this.state;
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                        <i className="far fa-check-circle"></i>
                        <p>Vos horaires d'ouverture ont bien été modifiés.</p>
                    </div>
                }
                <Header />
                <div className="Account">
                    <TopOptions title="Horaires d'ouverture de la pharmacie">
                        <button onClick={() => this.props.history.push('/account/pharmacy_timetable_edit')} className="btn_bleu">Modifier</button>
                    </TopOptions>
                    <div className="info_profile_and_sub_menu">
                        <SideMenu />
                        <div style={{ width: "74%" }}>
                            {/* <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc", marginBottom: "1%" }}>
                                <div className="profil_info_card_header">
                                    <h2>Horaire d'ouverture de la pharmacie</h2>
                                </div>
                                <div className="profil_info_card_bloc_content">
                                    <div className="param_user_guide">
                                        <p>Certaines informations nécessitent une vérification par notre équipe de modération..</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="ParametresInfos">
                                <div className="profilInfoCard">
                                    <div className="profil_info_card_header">
                                        <h2>Horaires d'ouverture de la pharmacie</h2>
                                    </div>
                                    <div className="bloc_content">
                                        <div className="ParametresGrid">
                                            <InfoCard
                                                thead={thead}
                                                tbody={{
                                                    dataIsLoaded: is_loaded,
                                                    data: openingHours ? openingHours : [],
                                                    action: ''
                                                }}
                                                {...this.props}>
                                            </InfoCard>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyTimetable;
