import React from "react";

/* Icons */
import dynamiser from "../assets/dynamiser.svg";
import augmenter from "../assets/augmenter.svg";
import mieuxGerer from "../assets/mieux_gerer.svg";
import imageDynamique from "../assets/image-dynamique.svg";
import optimiser from "../assets/optimiser.svg";
import fideliser from "../assets/fideliser.svg";

function Section5() {
    return (
        <section className="section-5 col-12" id="section5">
            <div className="row container">
                <h3 className="h3" data-aos='slide-right'>- Avantages</h3>
                <h1 className="h1" data-aos='slide-right'>
                    Pourquoi choisir <br />
                    <strong>MonPharmacien ?</strong>
                </h1>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={dynamiser} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Dynamiser</strong> le trafic de votre officine
                        </p>
                    </div>
                </div>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={augmenter} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Augmenter</strong> votre chiffre d’affaires
                        </p>
                    </div>
                </div>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={mieuxGerer} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Mieux gérer</strong> le flux au comptoir
                        </p>
                    </div>
                </div>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={imageDynamique} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Renvoyer une image dynamique</strong> de votre pharmacie
                        </p>
                    </div>
                </div>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={optimiser} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Optimiser</strong> votre gestion de stock
                        </p>
                    </div>
                </div>

                <div className="content col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12" data-aos='zoom-in-up'>
                    <img className="icon" src={fideliser} alt="" />
                    <div className="text">
                        <p className="p">
                            <strong>Fidéliser</strong> vos patients-clients
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section5;
