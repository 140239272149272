import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import TableModel from '../../Helpers/TableModal/TableModel';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import prescriptionsService from '../../services/prescriptions.service';
import { BasicSearchComp } from '../../Helpers/SearchComponents/OrdonnanceSearchComponent';
import Store from '../../Store/configStore';
import './OrdonnancesTable.css';

const thead = [
    { property: "id", label: "ID de l’ordonnance", type: "string", width: "25%" },
    { property: "patientName", label: "Prénom du patient", type: "string", width: "25%" },
    // { property: "patientPhone", label: "Téléphone du patient", type: "string", width: "20%" },
    { property: "status", label: "Statut", type: "status", width: "25%" },
    { property: "updatedAt", label: "Date du dernier statut", type: "date", width: "25%" },
]

class OrdonnancesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            elementsPerPage: 20,
            order: "DESC",
            orderBy: "id",
            data: [],
            totalItems: 0,
            searchFields: this.initialsearchFields,
            searchToggle: false,
            basicSearchToggle: false,
            advancedSearchToggle: false,
            searchType: "simple",
            is_loaded: true,
            isModel: false,
            tableActions: [],
            allowedEditTable: [],
            country: null
        };
        this.setSearchToggle = this.setSearchToggle.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    initialsearchFields = {
        id: '',
        patientName: '',
        patientPhone: '',
        status: '',
        updatedAt: '',
    }

    componentDidMount() {
        document.title = "Ordonnances - Sobrus Pharma";
        this.setState({ is_loaded: false, country: Store?.getState()?.handleCurrentCountry?.currentCountry }, () => {
            if (this.state.country) {
                if (this.state.country === "ma") {
                    window.location.href = '/home'
                }
            }
            this.getAllPrescriptions();
        });
    }

    getAllPrescriptions = async () => {
        const { searchFields, currentPage, elementsPerPage, order, orderBy } = this.state;
        try {
            let response = await prescriptionsService.getAllPrescriptions(searchFields, currentPage, elementsPerPage, order, orderBy);
            this.setState({ is_loaded: true, data: response.data.prescriptions ? this.prepareData(response.data.prescriptions) : [], totalItems: response.data.total }, () => console.log('ivebeen here'));
        } catch (error) {
            console.warn(error);
        }
    };

    setSearchToggle(type) {
        if (type === "simple") {
            if (!this.state.advancedSearchToggle) {
                this.setState({ basicSearchToggle: !this.state.basicSearchToggle, searchType: type })
            }
            if (this.state.advancedSearchToggle) {
                this.setState({ basicSearchToggle: false, advancedSearchToggle: false, searchType: type })
            }
        } else {
            if (this.state.advancedSearchToggle) {
                this.setState({ basicSearchToggle: false, advancedSearchToggle: false, searchType: type })
            } else {
                this.setState({ basicSearchToggle: true, advancedSearchToggle: true, searchType: type })
            }
        }
        this.setState({ searchToggle: !this.state.searchToggle, searchType: type })
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.searchFields));
        fieldsCopy[input] = e.target.value;
        this.setState({ searchFields: fieldsCopy });
    };

    prepareData = (data) => {
        let result = [];
        console.log(data)
        data.forEach(el => {
            let Ordonnance = {
                id: el.id,
                patientName: el.patient && el.patient?.firstName,
                patientPhone: el.patient && el.patient.mobileNumber,
                status: el.status,
                updatedAt: el.updatedAt
            }
            result.push(Ordonnance)
        });
        return result;
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAllPrescriptions());
        }
    }

    onClickColumn = (property) => {
        if (this.state.order === "ASC") {
            this.setState({ orderBy: property, order: "DESC", currentPage: 1, is_loaded: false }, () => {
                this.getAllPrescriptions();
            })
        } else {
            this.setState({ orderBy: property, order: "ASC", currentPage: 1, is_loaded: false }, () => {
                this.getAllPrescriptions();
            })
        }
    }

    render() {
        const { searchFields, searchToggle, searchType, data, currentPage, totalItems, is_loaded, basicSearchToggle, advancedSearchToggle } = this.state
        return (
            <div className="OrdonnancesTable_container">
                <Header />
                <div className="OrdonnancesTable">
                    <TopOptions title="Ordonnances">

                    </TopOptions>
                    <TableModel
                        BasicSearchComponent={<BasicSearchComp
                            onKeyDown={this.handleKeyDown}
                            onSearch={() => this.setState({ pageNumber: 1, is_loaded: false }, () => { this.getAllPrescriptions() })}
                            columns={{
                                id: { value: searchFields.id.value, onChange: this.handleChange("id") },
                                patientName: { value: searchFields.patientName.value, onChange: this.handleChange("patientName") },
                                patientPhone: { value: searchFields.patientPhone.value, onChange: this.handleChange("patientPhone") },
                                status: { value: searchFields.status.value, onChange: this.handleChange("status") },
                                updatedAt: { value: searchFields.updatedAt.value, onChange: this.handleChange("updatedAt") },
                            }}
                        />}
                        AdvancedSearchComponent={''}
                        title="Liste des ordonnances"
                        onReload={() => this.setState({
                            currentPage: 1,
                            is_loaded: false,
                            searchFields: this.initialsearchFields,
                            order: "DESC",
                            orderBy: "id",
                        }, () => this.getAllPrescriptions())}
                        thead={thead}
                        onClickColumn={this.onClickColumn}
                        tbody={{
                            dataIsLoaded: is_loaded,
                            data: data,
                            onClickRow: (id) => {
                                this.props.history.push(`/Ordonnances/view/${id}`)
                            },
                            actions: [<p></p>]
                        }}
                        allowedEditTable={this.state.allowedEditTable}
                        tfoot={{
                            colSpan: 7,
                            pageNumber: currentPage,
                            totalItems: totalItems,
                            PrevPage: () => this.setState({
                                currentPage: currentPage - 1,
                                is_loaded: false,
                            },
                                () => {
                                    this.getAllPrescriptions();
                                }),
                            nextPage: () => this.setState({
                                currentPage: currentPage + 1,
                                is_loaded: false,
                            },
                                () => {
                                    this.getAllPrescriptions();
                                }),
                        }}
                        searchToggle={searchToggle}
                        advancedSearchToggle={advancedSearchToggle}
                        basicSearchToggle={basicSearchToggle}
                        searchType={searchType}
                        searchToggleFunc={this.setSearchToggle}
                        {...this.props}
                    />
                    <ContactButton />
                </div >
            </div>
        );
    }
}

export default OrdonnancesTable;
