import React from 'react';
import './Pagination.css'


export const TablePagination = (props) => {
  const { colSpan, pageNumber, totalItems, onNextBtnClick, onPrevBtnClick } = props;
  return (
    <tbody>
      <tr className="pagination_tr">
        <td colSpan={colSpan} className="table_pagination">
          <div style={{ display: "inline-block", marginRight: "20px" }}></div>
          <div style={{ display: "inline-block" }}>
            {pageNumber > 1 && <a className="sprite-table-prev-btn" onClick={onPrevBtnClick} />}
            <span className="table_page">{pageNumber}</span>
            {pageNumber < totalItems / 20 && <a className="sprite-table-next-btn" onClick={onNextBtnClick} />}
          </div>
        </td>
      </tr>
    </tbody>
  );
};
