import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import logo from "../assets/monpharmacien.svg";
import phoneGreen from "../assets/phone-green.svg";
import mailGreen from "../assets/mail-green.svg";
import copy from "../assets/copy-green.svg";
import copyRights from "../assets/copyrights_icon.svg";
import { Link } from "react-router-dom";

function Footer() {
    const [value, setValue] = useState("");
    const textRef = useRef();

    useEffect(() => {
        setValue(textRef.current.innerText);
    }, []);

    return (
        <div className="footer-container">
            <div className="footer-info">
                <div className="footer-card footer-col1">
                    <img className="logo" src={logo} alt="" />
                    <p style={{ lineHeight: "1.3em" }}>
                        MonPharmacien est le premier annuaire interactif de pharmacies.
                        Cet outil permet de rapprocher le pharmacien de ses patients et de
                        rendre accessibles toutes les informations des pharmacies pour garantir une meilleure santé.
                    </p>
                </div>
                <div className="footer-card footer-col2">
                    <h2>Nos solutions</h2>
                    <a href="http://pharma.sobrus.com" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Sobrus pharma
                    </a>
                    <a href="https://labs.sobrus.com" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Sobrus Labs
                    </a>
                    <a href="https://sns.sobrus.com" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Sobrus SNS
                    </a>
                    <a href="https://med.sobrus.com" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Sobrus MED
                    </a>
                </div>
                <div className="footer-card footer-col3">
                    <h2>Nos outils</h2>
                    <a href="http://monmedicament.ma" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Annuaire Médicaments
                    </a>
                    <a href="https://monordonnance.sobrus.com" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Ordonnance Électronique
                    </a>
                    <a href="http://chezledocteur.ma" target="_blank" rel="noreferrer">
                        <span>&#8226;</span> Annuaire Médecins
                    </a>
                </div>
                <div className="footer-card footer-col4">
                    <h2>Contactez-nous</h2>
                    <div className="footer-contact">
                        <img src={phoneGreen} alt="" />
                        <a rel="noreferrer">
                            +33 3 62 27 90 70
                        </a>
                    </div>
                    <div className="footer-contact">
                        <img src={mailGreen} alt="" />
                        <CopyToClipboard text={value}>
                            <span className="sobrus-email-footer">
                                <a rel="noreferrer" ref={textRef} style={{ cursor: "pointer" }}>
                                    support@sobrus.com
                                </a>
                                <img src={copy} alt="" />
                            </span>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="copyrights-bg">
                <div className="footer-copyrights">
                    <p className="p">
                        MonPharmacien&nbsp;
                        <img src={copyRights} alt="" />
                        &nbsp;2021
                    </p>
                    <Link to="/conditions" className="a">
                        CGU
                    </Link>
                    <Link to="/politiques" className="a">
                        Politique de confidentialité
                    </Link>
                </div>
            </div>
        </div >
    );
}

export default Footer;
