import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, NavLink, Redirect, withRouter } from 'react-router-dom';
import { LOGIN_PHARMACIST } from '../../config';
import authService from '../../services/auth.service';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';
import './Header.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            selected: 1,
            totalNotifs: 0,
            totalUnseenNotifs: 0,
            admin: null,
            loadingAdmin: false,
            loadingNotif: false,
            role: "",
            allNotifications: [],
            showuserparams: false,
            userData: null,
            loader: true,
            country: null
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getUser();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleUserParams = () => {
        this.setState({
            showuserparams: !this.state.showuserparams
        });
    };

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showuserparams: false
            });
        }
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data }, () => this.getPharmacieByslug());
        } catch (error) {
            console.log(error);
            if (error.response.data.message === "code1") {
                localStorage.clear();
                window.location = LOGIN_PHARMACIST
            }
        }
    }

    logOut = async () => {
        await authService.logout()
        // return <Redirect to={"/Login"} />
    }

    getPharmacieByslug = async () => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(this.state.userData?.pharmacySlug);
            this.props.dispatch({ type: 'HANDLE_CURRENT_COUNTRY', value: response?.data?.pharmacy?.country?.toLowerCase() })
            this.setState({country:response?.data?.pharmacy?.country?.toLowerCase(), loader: false})
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { country } = this.state;
        return (
            <>
                <div className="Header">
                    <div className="Wrapper">
                        <Link to={"/home"}>
                            <div className="Logo"><span>SOBRUS </span>PHARMA</div>
                        </Link>
                        <nav className="Nav">
                            <ul className='Nav_ul'>
                                <NavLink exact to={"/home"} >
                                    <li className='Nav_li'>
                                        <i className="Text">Tableau de bord</i>
                                    </li>
                                </NavLink>
                                {country !== "ma" && <NavLink to={"/ordonnances"}>
                                    <li className='Nav_li' >
                                        <i className="Text">Ordonnances</i>
                                    </li>
                                </NavLink>}
                                {country !== "ma" && <NavLink to={"/reports"}>
                                    <li className='Nav_li' >
                                        <i className="Text">Rapports</i>
                                    </li>
                                </NavLink>}
                                <NavLink to={"/account"}>
                                    <li className='Nav_li' >
                                        <i className="Text">Paramètres</i>
                                    </li>
                                </NavLink>
                            </ul>
                        </nav>
                        <div className="Show_User_Params" onClick={() => this.toggleUserParams()} ref={this.wrapperRef}>
                            <div className="profile_avatar" href="#" title="profil"> {this.state.userData ? this.state.userData.firstName.charAt(0) + " " + this.state.userData.lastName.charAt(0) : "AA"}
                            </div>
                            <div className="UserName"> {this.state.userData ? this.state.userData.firstName + " " + this.state.userData.lastName : "Chargement en cours..."} </div>
                            <span className="profil_arrow"></span>
                            <div className={this.state.showuserparams ? "SubMenuUserParam SubMenuUserDisplay" : " SubMenuUserParam"}>
                                <div className="InfosList">
                                    <div className="" onClick={() => this.props.history.push('/account')}>
                                        <p className="" >Profil</p>
                                    </div>
                                    <div className="" onClick={() => this.logOut()}>
                                        <p> Se déconnecter </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        handleCurrentCountry: state.handleCurrentCountry.currentCountry,
    }
}

export default withRouter(connect(mapStateToProps)(Header));
