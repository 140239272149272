import React from "react";
import MockUpImg from "../assets/MonPharmacien-App.png";
//import Bg from '../assets/bg-section-1.svg';
import whiteArrow from "../assets/white-arrow.svg";
import { Link } from "react-scroll";

function Section1() {
    return (
        <section className="section-1 col-12" id="section1" style={{ zIndex: "10" }}>
            <div className="container row">
                <div className="col-xxl-6  col-xl-6 col-lg-6 col-md-12 col-sm-12" data-aos='fade-right'>
                    <h1 className="h1">
                        Proposez <br /> le <strong>Click & Collect</strong> à vos patients !
                    </h1>
                    <p className="p">
                        Recevez et gérez à l’avance les ordonnances de vos patients grâce au module{" "}
                        <strong>Click & Collect</strong> de <strong>Sobrus Pharma</strong>.
                    </p>
                    <br />
                    <p className="p p_lil">* Ce service est disponible exclusivement en France & en Belgique.</p>
                    <br />
                    <Link to="compte" spy={true} smooth={true} offset={-200} duration={500}>
                        <button className="btn">
                            Demander un compte gratuit
                            <img src={whiteArrow} alt="" />
                        </button>
                    </Link>
                </div>

                <div className="col-xxl-6  col-xl-6 col-lg-6 col-md-12 col-sm-12" style={{ zIndex: "1" }} data-aos="fade-left">
                    <img src={MockUpImg} alt="Test" className="img-fluid" />
                </div>
            </div>
        </section>
    );
}

export default Section1;
