import { useState, useEffect } from "react";
import logo from "../assets/monpharmacien.svg";
import whiteArrow from "../assets/white-arrow.svg";
import menuMobile from "../assets/menu-icon-white.svg";
import menuClose from "../assets/menu-icon-close.svg";
import { Link } from "react-scroll";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { LOGIN_PHARMACIST } from '../../../../config';

function Header() {
    const [isBoxVisible, setIsBoxVisible] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
        return () => {
            window.removeEventListener("scroll", null);
        };
    }, []);

    const toggleBox = () => {
        setIsBoxVisible((prev) => !prev);
    };

    return (
        <div className={`landing_page_header ${scrolled ? "fixed-nav" : ""}`}>
            <div className="container temp">
                <NavLink to='/' activeClassName="selected" >
                    <img className="logo" src={logo} alt="MonPharmacien" height="45" />
                </NavLink>
                <nav>
                    {location.pathname === "/" && (
                        <>
                            <Link
                                activeClass="is-active"
                                to="section1"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={100}
                            >
                                <span></span>
                                Accueil
                            </Link>
                            <Link
                                activeClass="is-active"
                                to="section2"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                            >
                                <span></span>
                                Présentation
                            </Link>
                            <Link
                                activeClass="is-active"
                                to="section3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                            >
                                <span></span>
                                Comment ça marche ?
                            </Link>
                            <Link
                                activeClass="is-active"
                                to="section5"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                            >
                                <span></span>
                                Avantages
                            </Link>
                            <div>
                                <button className="btn-outline" onClick={() => window.open(LOGIN_PHARMACIST, '_blank')}>Se connecter</button>
                                <Link
                                    to="compte"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                    className="btn"
                                >
                                    Demander un compte gratuit
                                    <img src={whiteArrow} alt="" />
                                </Link>
                            </div>
                        </>
                    )}

                    {location.pathname !== "/" && (
                        <>
                            <NavLink to="/" isActive={() => {
                                if (window.location?.pathname === "/politiques") {
                                    return false;
                                }
                            }}>Accueil</NavLink>
                            <NavLink isActive={() => {
                                if (window.location?.pathname === "/politiques") {
                                    return false;
                                }
                            }} to="/#section2">Présentation</NavLink>
                            <NavLink isActive={() => {
                                if (window.location?.pathname === "/politiques") {
                                    return false;
                                }
                            }} to="/#section3">Comment ça marche ?</NavLink>
                            <NavLink isActive={() => {
                                if (window.location?.pathname === "/politiques") {
                                    return false;
                                }
                            }} to="/#section5">Avantages</NavLink>
                            <button className="btn-outline"  onClick={() => window.open(LOGIN_PHARMACIST, '_blank')}>Se connecter</button>
                            <NavLink to="/#compte" className="btn">
                                Demander un compte gratuit
                                <img src={whiteArrow} alt="" />
                            </NavLink>
                        </>
                    )}
                </nav>

                <div className="btnMenu" onClick={toggleBox}>
                    <img className="" src={menuMobile} alt="" />
                </div>
                <div className={`${isBoxVisible ? "menuModal" : "hidden"}`}>
                    <ul>
                        <li>
                            <Link
                                activeClass="is-active"
                                to="section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                                onClick={toggleBox}
                            >
                                <span></span>
                                Accueil
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="is-active"
                                to="section2"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                                onClick={toggleBox}
                            >
                                <span></span>
                                Présentation
                            </Link>
                        </li>

                        <li>
                            <Link
                                activeClass="is-active"
                                to="section3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                                onClick={toggleBox}
                            >
                                <span></span>
                                Comment ça marche ?
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClass="is-active"
                                to="section4"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={100}
                                onClick={toggleBox}
                            >
                                <span></span>
                                Avantages
                            </Link>
                        </li>
                        <li>
                            <button className="btn-outline" onClick={() => window.open(LOGIN_PHARMACIST, '_blank')}>Se connecter</button>
                        </li>
                        <li>
                            <Link
                                to="compte"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={500}
                                className="btn"
                            >
                                Demander un compte gratuit
                                <img src={whiteArrow} alt="" />
                            </Link>
                        </li>

                        <li className="btnClose" onClick={toggleBox}>
                            <img className="" src={menuClose} alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Header);
