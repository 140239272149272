import React, { Component } from 'react';
import SearchSelect from '../../Helpers/AsyncSelect';
import ButtonLeader from '../../Helpers/ButtonLeader/ButtonLeader';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import './generalInfoEdit.css';

class generalInfoEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacieData: null,
            slug: null,
            fields: {
                ...this.fieldsData
            },
            errors: {},
            cities: [],
            sectors: [],
            selectedCity: null,
            selectedsector: null,
            cityId: null,
            sectorId: null,
            loader: true,
            buttonLeader: false
        }
    }

    fieldsData = {
        firstName: '',
        lastName: '',
        city: '',
        address: '',
        sector: '',
        postalCode: '',
        phone: '',
        degreeSchool: '',
        degreeYear: '',
        lat: '',
        log: ''
    }

    componentDidMount() {
        const slug = this.props && this.props.location.state.slug
        this.getPharmacieByslug(slug);
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            const selectedCity = {
                value: response.data.pharmacy?.city?.id,
                label: response.data.pharmacy?.city?.name
            }
            const selectedsector = {
                value: response.data.pharmacy?.sector?.id,
                label: response.data.pharmacy?.sector?.name
            }
            let fieldsCopy = this.state.fields
            const gps = response.data.pharmacy?.gpsLocation
            if (gps !== undefined) {
                var X = gps.split(" ")
            }
            fieldsCopy = {
                ...response.data.pharmacy,
                lat: gps && X[0],
                log: gps && X[1],
                firstName: response.data.pharmacy?.pharmacist?.firstName,
                lastName: response.data.pharmacy?.pharmacist?.lastName,
                degreeSchool: response.data.pharmacy?.pharmacist?.degreeSchool,
                degreeYear: response.data.pharmacy?.pharmacist?.degreeYear,
            }
            this.setState({
                fields: fieldsCopy, slug,
                pharmacieData: response.data.pharmacy, selectedCity,
                selectedsector, cityId: response.data.pharmacy?.city?.id,
                sectorId: response.data.pharmacy?.sector?.id,
                loader: false
            }, () => console.log(this.state.fields))
        } catch (error) {
            console.log(error);
        }
    }

    handleValidation = async () => {
        const { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        if (fields["phone"] && fields["phone"] !== "") {
            if (!fields["phone"].match(/((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9\s]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9\s]{3}\)|[1-9\s]{0,3})(?:((?: |-)[0-9\s]{2}){4}|((?:[0-9\s]{2}){4})|((?: |-)[0-9\s]{3}(?: |-)[0-9\s]{4})|([0-9\s]{7}))/)) {
                formIsValid = false;
                errors["phone"] = "Seuls les chiffres sont acceptés, minimum 10 caractères";
            }
        }
        this.setState({ errors: { ...this.state.errors, ...errors, } });
        return formIsValid;
    };

    suggestDataPharmacy = async () => {
        this.setState({ errors: {} }, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                this.setState({ buttonLeader: true }, async () => {
                    try {
                        const data = {
                            pharmacy_suggestion: {
                                name: this.state.fields.name,
                                firstName: this.state.fields.firstName,
                                lastName: this.state.fields.lastName,
                                degreeSchool: this.state.fields.degreeSchool,
                                pharmacyId: this.state.pharmacieData.id,
                                phone: this.state.fields.phone,
                                cityId: this.state.cityId,
                                address: this.state.fields.address,
                                sectorId: this.state.sectorId,
                                gpsLocation: this.state.fields.log + ' ' + this.state.fields.lat
                            }
                        }
                        await pharmaciesServices.suggestDataPharmacy(data);
                        this.props.history.push('/account/generalinfo', {
                            successMessage: true,
                        })
                    } catch (error) {
                        console.log(error);
                    }
                })
            }
        }, () => {
            this.setState({ buttonLeader: false })
        });
    }

    handleChange = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.fields));
        fieldsCopy[input] = e.target.value;
        this.setState({ fields: fieldsCopy });
    };

    render() {
        const { fields, errors, cityId, loader, buttonLeader } = this.state
        return (
            <>
                {loader && <Loader />}
                <Header />
                <TopOptions title="Informations générales">
                    {!buttonLeader ? <button id="printer" className="btn_bleu" onClick={() => this.suggestDataPharmacy()}>Enregistrer</button> :
                        <button className="btn_bleu_leader" ><ButtonLeader /></button>}
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Informations générales</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div className="ParametresGridDetail">
                                        <div className="form_row">
                                            <label htmlFor="accountName">Nom de la pharmacie :</label>
                                            <input placeholder="Nom de la pharmacie" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("name")} value={fields["name"]} />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="cabinetPhoneNumber">ville de la pharmacie :</label>
                                            <SearchSelect
                                                className="form-control SearchInputs"
                                                fetchedUrl='/cities'
                                                HTTPMethod="getcities"
                                                receivedObject="cities"
                                                optionLabelProperty="name"
                                                optionValueProperty="id"
                                                value={this.state.selectedCity}
                                                country={fields?.country?.toLowerCase()}
                                                placeholder="Entrez la ville"
                                                onChange={(e) => {
                                                    if (e) {
                                                        this.setState({ selectedCity: [], cities: [], sectorId: null, selectedsector: '', sectors: [] }, () => {
                                                            let city = {
                                                                id: e.value
                                                            }
                                                            this.state.cityId = city;
                                                            this.setState({
                                                                cityId: e.value,
                                                                selectedCity: [...this.state.selectedCity, e]
                                                            })
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    loadingIndicator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    indicatorSeparator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                    }),
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderWidth: "2px",
                                                        borderRadius: "none",
                                                        boxShadow: "none",
                                                        height: "42px",
                                                        borderColor: "#d9e3e8",
                                                        ":hover": {
                                                            borderColor: "none",
                                                            borderWidth: "2px",
                                                        },
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontFamily: "OpenSans",
                                                        fontWeight: "600",
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.isSelected && "#ffff",
                                                        fontFamily: "OpenSans",
                                                        borderBottom: " 1px solid #d9e3e8",
                                                        padding: "5px",
                                                        color: "#7189a8",
                                                        fontSize: "13px",
                                                        textAlign: 'start',
                                                        ":hover": {
                                                            color: "#fff",
                                                            backgroundColor: "#00beb0",
                                                        },
                                                    }),
                                                    clearIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingRight: 0,
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingLeft: 0,
                                                    })
                                                }}
                                            />
                                            <p className="erreurInputText">{errors["city"] ? <><i className="far fa-times-circle"></i> {errors["city"]}</> : ""}</p>
                                        </div>

                                        <div className="form_row">
                                            <label htmlFor="accountName">Longitude :</label>
                                            <input placeholder="Longitude" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("log")} value={fields["log"]} />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="accountName">Téléphone fixe :</label>
                                            <input placeholder="Téléphone fixe" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("phone")} value={fields["phone"]} />

                                            {errors["phone"] ? <p className="erreurInputText"> <><i className="far fa-times-circle"></i> {errors["phone"]}</> </p> : ""}
                                        </div>
                                    </div>
                                    <div className="ParametresGridDetail">
                                        <div className="form_row">
                                            <label htmlFor="cabinetAddress">Adresse de la pharmacie :</label>
                                            <input placeholder="Adresse de la pharmacie" type="text" name="cabinetAddress" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="cabinetAddress" onChange={this.handleChange("address")} value={fields["address"]} />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="cabinetAddress">secteur :</label>
                                            <SearchSelect
                                                className="form-control SearchInputs"
                                                fetchedUrl='/sectors'
                                                HTTPMethod="getsectors"
                                                receivedObject="sectors"
                                                optionLabelProperty="name"
                                                optionValueProperty="id"
                                                value={this.state.selectedsector}
                                                country={fields?.country?.toLowerCase()}
                                                placeholder="Entrez le secteur"
                                                cityId={cityId}
                                                onChange={(e) => {
                                                    if (e) {
                                                        this.setState({ selectedsector: [], sectors: [] }, () => {
                                                            let sector = {
                                                                id: e.value
                                                            }
                                                            this.state.sectorId = sector;
                                                            this.setState({
                                                                sectorId: e.value,
                                                                selectedsector: [...this.state.selectedsector, e]
                                                            })
                                                        })
                                                    }
                                                }}
                                                styles={{
                                                    loadingIndicator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    indicatorSeparator: (provided, state) => ({
                                                        ...provided,
                                                        display: "none",
                                                    }),
                                                    container: (provided, state) => ({
                                                        ...provided,
                                                    }),
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        borderWidth: "2px",
                                                        borderRadius: "none",
                                                        boxShadow: "none",
                                                        height: "42px",
                                                        borderColor: "#d9e3e8",
                                                        ":hover": {
                                                            borderColor: "none",
                                                            borderWidth: "2px",
                                                        },
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontFamily: "OpenSans",
                                                        fontWeight: "600",
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.isSelected && "#ffff",
                                                        fontFamily: "OpenSans",
                                                        borderBottom: " 1px solid #d9e3e8",
                                                        padding: "5px",
                                                        color: "#7189a8",
                                                        fontSize: "13px",
                                                        textAlign: 'start',
                                                        ":hover": {
                                                            color: "#fff",
                                                            backgroundColor: "#00beb0",
                                                        },
                                                    }),
                                                    clearIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingRight: 0,
                                                    }),
                                                    dropdownIndicator: (provided, state) => ({
                                                        ...provided,
                                                        paddingLeft: 0,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="form_row">
                                            <label htmlFor="accountName">Latitude :</label>
                                            <input placeholder="Latitude" type="text" name="accountName" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" id="accountName" onChange={this.handleChange("lat")} value={fields["lat"]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </>
        );
    }
}

export default generalInfoEdit;
