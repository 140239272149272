import axios from "axios";
import { api_url } from "../config";
import authService from "./auth.service";

const authAxios = axios.create({
    baseURL: api_url + "app/pharmacies"
});

const authAxiosPharmaciests = axios.create({
    baseURL: api_url + "app/pharmacists"
});

class pharmaciesServices {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                if (401 === error.response.status) {
                    if (error.response.data.message === "code1") {
                        localStorage.clear();
                        window.location.href = '/login'
                    } else if (error.response.data.message === "code2") {
                        try {
                            await authService.refresh();
                            window.location.reload();
                        } catch (error) {
                            console.log(error)
                        }
                    } else if (error.response.data.message === "code3") {
                        localStorage.clear();
                        window.location.href = '/login'
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    getPharmacieByslug(slug) {
        return authAxios.get(`/${slug}`, { withCredentials: true });
    }

    suggestDataPharmacy(data) {
        return authAxios.post(`/suggest`, data, { withCredentials: true });
    }

    suggestDataPharmacists(data) {
        return authAxiosPharmaciests.post(`/suggest`, data, { withCredentials: true });
    }

    getPaymentMethods() {
        return authAxios.get(`/payment-methods/get`, { withCredentials: true });
    }

    getLanguages() {
        return authAxios.get(`/languages/get`, { withCredentials: true });
    }

    getServices() {
        return authAxios.get(`/services/get`, { withCredentials: true });
    }

    updatePharmacyImages(data) {
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        return authAxios.post(`/update-photos`, data, { ...config, withCredentials: true });
    }

    updatePharmacyDisplayParams(data) {
        return authAxios.post(`/update-display-params`, data, { withCredentials: true });
    }

    updatePharmacyOpningHours(id, data) {
        return authAxios.patch(`/${id}/opening-hours/update`, data, { withCredentials: true });
    }

}

export default new pharmaciesServices()