import React, { Component } from 'react';
import './Dashboard.css'
import InfiniteScroll from "react-infinite-scroll-component";
// import InfiniteScroll from 'react-infinite-scroller';
import Menu from '../../Helpers/Menu/Menu'
import Post from '../../Helpers/Post/Post';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import AddsComponent from '../../Helpers/AddsComponent/AddsComponent';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import data from './posts.json'
import Header from '../../Helpers/Header/Header';
import userService from '../../services/user.service';
import Loader from '../../Helpers/Loader';
import Store from '../../Store/configStore';

const DashBoardData = data.reverse()

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: DashBoardData.slice(0, 3),
            itemsNumber: 8,
            prescriptionData: null,
            loader: true,
            country: null
        };
    }

    componentWillMount() {
        document.title = "Tableau de bord - Sobrus Pharma";
        this.getUser()
        this.setState({ country: Store?.getState()?.handleCurrentCountry?.currentCountry });
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data, loader: false })
        } catch (error) {
            console.log(error);
            if (error.response?.data?.message === "code1") {
                localStorage.clear();
                window.location = "https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3D10008%26scope%3Dopenid%2520offline_access%2520profile%2520email%26redirect_uri%3Dhttps%3A%2F%2Fapi.monpharmacien.sobrus.ovh%2Fauth%2Ftoken%26prompt%3Dlogin"
            }
        }
    }

    fetchData = () => {
        setTimeout(() => {
            this.setState({
                items: [...this.state.items, ...DashBoardData.slice(this.state.itemsNumber, this.state.itemsNumber + 4)],
                itemsNumber: this.state.itemsNumber + 4
            })
        }, 1000);
    };

    handleScroll = e => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollHeight - scrollTop === clientHeight) {
            this.fetchData();
        }
    }

    render() {
        return (
            <div >
                {this.state.loader && <Loader />}
                <Header />
                <div className="Main_container">
                    <TopOptions title="Tableau de bord" />
                    <div className="Content" >
                        <Menu />
                        <div className="Post_container">
                            {!this.state.loader && <InfiniteScroll
                                style={{ overflow: 'none' }}
                                dataLength={this.state.items.length}
                                next={this.fetchData}
                                hasMore={true}
                                // loader={<div className="PostsLoader">
                                //     <p>Chargement en cours...</p>
                                // </div>}
                                scrollableTarget={'html'}>
                                <div>
                                    {this.state.items.map((data, index) => (<Post key={"key" + index} data={data} />))}
                                </div>
                            </InfiniteScroll>}
                        </div>
                        <AddsComponent />
                    </div>
                    <ContactButton />
                </div>
            </div >
        );
    }
}

export default Dashboard;