import React, { Component } from "react";
import ContactButton from "../../Helpers/ContactButton/ContactButton";
import './404Page.css'

class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "Erreur inattendue"
        };
    }
    componentDidMount() {
        let errorTitle = this.props.location.state.codeError
        document.title = "Erreur " + errorTitle + " - MonPharmacien";
        let message = this.props.location.state.message;
        if (message) {
            this.setState({ message });
        }
    }

    render() {
        return (
            <div className="NotFoundPage">
                <p className="NotFoundTitle"> {this.state.message} </p>
                <div onClick={() => this.props.history.push("/")}>
                    <div className="notFoundButton">Accueil</div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default NotFoundPage;