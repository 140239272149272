import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './SideMenu.css'

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showParameter: 0
        }
    }

    componentDidMount() {
        if (window.location.pathname === '/account' || window.location.pathname === '/account/useraccountlogins') {
            this.setState({ showParameter: 1 })
        }
        if (window.location.pathname === '/account/generalinfo' || window.location.pathname === '/account/generalinfoedit'
            || window.location.pathname === '/account/generalinfo_pharmasist' || window.location.pathname === '/account/generalinfo_pharmasist'
            || window.location.pathname === '/account/pharmacy_service' || window.location.pathname === '/account/pharmacy_service_edit'
            || window.location.pathname === '/account/pharmacy_payment' || window.location.pathname === '/account/pharmacy_payment_edit'
            || window.location.pathname === '/account/pharmacy_languages' || window.location.pathname === '/account/pharmacy_languages_edit'
            || window.location.pathname === '/account/pharmacy_timetable' || window.location.pathname === '/account/pharmacy_timetable_edit'
            || window.location.pathname === '/account/display_params' || window.location.pathname === '/account/display_params_edit' ||
            window.location.pathname === '/account/pharmacy_images') {
            this.setState({ showParameter: 2 })
        }
        if (window.location.pathname === '/account/facturetypes' || window.location.pathname === '/account/facturetypesedit' || window.location.pathname === '/account/factures' || window.location.pathname === '/account/facturesedit' || window.location.pathname === '/account/facturesseries' || window.location.pathname === '/account/facturesseriesedit') {
            this.setState({ showParameter: 3 })
        }
        if (window.location.pathname === '/account/appointmenttypes' || window.location.pathname === '/account/appointmenttypesedit' || window.location.pathname === '/account/averageappointmenttime' || window.location.pathname === '/account/averageappointmenttimeedit') {
            this.setState({ showParameter: 4 })
        }
    }

    render() {
        const { showParameter } = this.state;
        return (
            <div className="SideMenu bloc">
                <div className="bloc_param">
                    <ul className="param_nav" id="param_nav">
                        <ul className="Li">
                            <div className={showParameter === 1 ? "Li_container_active" : "Li_container_active"} onClick={() => showParameter === 1 ? this.setState({ showParameter: 0 }) : this.setState({ showParameter: 1 })}>
                                <p className="Col_1_li">Mes paramètres</p>
                                {
                                    showParameter === 1 ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>
                                }
                            </div>
                            <div className={showParameter === 1 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account')}>Profil</li>
                                <li className={window.location.pathname === '/account/useraccountlogins' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/useraccountlogins')}>Historique des connexions</li>
                            </div>
                        </ul>

                        <ul className="Li">
                            <div className={showParameter === 2 ? "Li_container_active" : "Li_container_active"} onClick={() => showParameter === 2 ? this.setState({ showParameter: 0 }) : this.setState({ showParameter: 2 })}>
                                <p className="Col_1_li">Ma page professionnelle</p>
                                {
                                    showParameter === 2 ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>
                                }
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/generalinfo' || window.location.pathname === '/admin/generalinfoedit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/generalinfo')}>Informations générales de la pharmacie</li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/generalinfo_pharmasist' || window.location.pathname === '/admin/generalinfo_pharmasist' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/generalinfo_pharmasist')}>Informations générales du pharmacien</li>

                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/pharmacy_service' || window.location.pathname === '/admin/pharmacy_service_edit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/pharmacy_service')}>Services proposés à la pharmacie</li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/pharmacy_payment' || window.location.pathname === '/admin/pharmacy_payment_edit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/pharmacy_payment')}>Moyens de paiement acceptés </li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/pharmacy_languages' || window.location.pathname === '/admin/pharmacy_languages_edit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/pharmacy_languages')}>Langues parlées à la pharmacie </li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/pharmacy_timetable' || window.location.pathname === '/admin/pharmacy_timetable_edit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/pharmacy_timetable')}>Horaires d'ouverture de la pharmacie</li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/pharmacy_images' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/pharmacy_images')}>Photos de la pharmacie</li>
                            </div>
                            <div className={showParameter === 2 ? "Under_list_display" : "Under_list"}>
                                <li className={window.location.pathname === '/account/display_params' || window.location.pathname === '/admin/display_params_edit' ? "Under_list_li" : "Under_list_li_is_Active"} onClick={() => this.props.history.push('/account/display_params')}>Paramètres d'affichage</li>
                            </div>
                        </ul>

                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(SideMenu);
