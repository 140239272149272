import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';
import './PharmacyPayment.css';

class PharmacyPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tEstablishmentParams: null,
            pharmacieData: null,
            paymentMethods: [],
            paymentMethodsChecked: [],
            noFav: false,
            loader: true
        }
    }

    componentDidMount() {
        this.getPaymentMethods()
        const state = this.props.location.state;
        if (state !== undefined) {
            if (state.successMessage) {
                this.setState({ noFav: true })
                this.emailNotefitacion();
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.getPharmacieByslug(response.data.pharmacySlug)
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ paymentMethodsChecked: response.data.pharmacy?.availablePaymentMethods, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    getPaymentMethods = async () => {
        try {
            let response = await pharmaciesServices.getPaymentMethods()
            this.setState({ paymentMethods: response.data?.payment_methods }, () => this.getUser())
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { paymentMethods, paymentMethodsChecked, loader, noFav } = this.state;
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                         <i className="far fa-check-circle"></i>
                        <p>Votre suggestion a été transmise avec succès.</p>
                    </div>
                }
                <Header />
                <TopOptions title="Moyens de paiement acceptés ">
                    <button id="printer" className="btn_bleu" onClick={() => this.props.history.push('/account/pharmacy_payment_edit')}>Modifier</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc", marginBottom: "1%" }}>
                            <div className="profil_info_card_header">
                                <h2>Moyens de paiement acceptés </h2>
                            </div>
                            <div className="profil_info_card_bloc_content">
                                <div className="param_user_guide">
                                    <p>Certaines informations nécessitent une vérification par notre équipe de modération..</p>
                                </div>
                            </div>
                        </div>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Moyens de paiement acceptés</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div style={{ display: "flex", flexWrap: 'wrap', width: "100%" }}>
                                        {
                                            paymentMethods.map((items) => {
                                                return <div className="user_detail_row" style={{ width: "49%", marginRight: "1%" }}>
                                                    <span style={{ marginBottom: '10px' }}>
                                                        {items?.label === "Cash" ? "Espèce" : items?.label === "Check" ? "Chèque" 
                                                        : items?.label === "Debit Card" ? "Carte bancaire": items?.label === "Credit" ? "Crédit" 
                                                        : items?.label === "Bill of Exchange" ? "Lettre de change" : items?.label === "Wire Transfer" ? "Virement bancaire" 
                                                        : items?.label === "Other" ? "Autres"  : "" }
                                                        </span>
                                                    <p className={paymentMethodsChecked.some(item => items.id === item.id) ? "ouiButton" : "nonButton"}>
                                                        {paymentMethodsChecked.some(item => items.id === item.id) ? 'Oui' : 'Non'}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyPayment;
