import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';
import './generalInfo.css';

class generalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tEstablishmentParams: null,
            userData: null,
            pharmacieData: null,
            fields: {
                ...this.fieldsData
            },
            slug: null,
            noFav: false,
            loader: true
        }
    }

    fieldsData = {
        firstName: '',
        lastName: '',
        city: '',
        address: '',
        sector: '',
        postalCode: '',
        phone: '',
        degreeSchool: '',
        degreeYear: '',
        lat: '',
        log: ''
    }


    componentDidMount() {
        this.getUser();
        const state = this.props.location.state;
        if (state !== undefined) {
            if (state.successMessage) {
                this.setState({ noFav: true })
                this.emailNotefitacion();
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }


    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.setState({ userData: response.data, slug: response.data.pharmacySlug }, () => this.getPharmacieByslug())
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async () => {
        const { slug } = this.state
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            let fieldsCopy = this.state.fields
            const gps = response.data.pharmacy?.gpsLocation
            if (gps !== undefined) {
                var X = gps.split(" ")
            }

            fieldsCopy = {
                ...response.data.pharmacy,
                lat: gps && X[0],
                log: gps && X[1],
            }
            this.setState({ pharmacieData: fieldsCopy, loader: false }, () => console.log(this.state.pharmacieData))
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { pharmacieData, slug, loader, noFav } = this.state
        return (
            <>
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                        <i className="far fa-check-circle"></i>
                        <p>Votre suggestion a été transmise avec succès.</p>
                    </div>
                }
                <Header />
                <div className="Account">
                    <TopOptions title="Informations générales de la pharmacie">
                        <button onClick={() => this.props.history.push({
                            pathname: '/account/generalinfoedit',
                            state: { slug: slug }
                        })} className="btn_bleu">Modifier</button>
                    </TopOptions>
                    <div className="info_profile_and_sub_menu">
                        <SideMenu />
                        <div style={{ width: "74%" }}>
                            <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc", marginBottom: "1%" }}>
                                <div className="profil_info_card_header">
                                    <h2>Informations générales de la pharmacie</h2>
                                </div>
                                <div className="profil_info_card_bloc_content">
                                    <div className="param_user_guide">
                                        <p>Certaines informations nécessitent une vérification par notre équipe de modération..</p>
                                    </div>
                                </div>
                            </div>
                            <div className="ParametresInfos">
                                <div className="profilInfoCard">
                                    <div className="profil_info_card_header">
                                        <h2>Informations générales de la pharmacie</h2>
                                    </div>
                                    <div className="bloc_content">
                                        <div className="ParametresGrid">
                                            <div className="ParametresGridDetail">
                                                <div className="user_detail_row">
                                                    <span>Nom de la pharmacie</span>
                                                    <p>{pharmacieData?.name ?? ""}</p>
                                                </div>
                                                <div className="user_detail_row">
                                                    <span>Ville de la pharmacie</span>
                                                    <p>{pharmacieData?.city?.name ?? ""}</p>
                                                </div>
                                                <div className="user_detail_row">
                                                    <span>Latitude</span>
                                                    <p>{pharmacieData?.lat ?? ""}</p>
                                                </div>
                                                <div className="user_detail_row">
                                                    <span>Téléphone fixe</span>
                                                    <p>{pharmacieData?.phone ?? ""}</p>
                                                </div>
                                            </div>
                                            <div className="ParametresGridDetail">
                                                <div className="user_detail_row">
                                                    <span>Adresse de la pharmacie</span>
                                                    <p>{pharmacieData?.address ?? ""}</p>
                                                </div>
                                                <div className="user_detail_row">
                                                    <span>Secteur</span>
                                                    <p>{pharmacieData?.sector?.name ?? ""}</p>
                                                </div>
                                                <div className="user_detail_row">
                                                    <span>Longitude</span>
                                                    <p>{pharmacieData?.log ?? ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </>
        );
    }
}

export default generalInfo;
