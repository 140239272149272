import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

class PharmacyLanguages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            spokenLanguages: [],
            pharmacyData: null,
            noFav: false,
            loader: true
        }
    }

    componentDidMount() {
        this.getLanguages()
        const state = this.props.location.state;
        if (state !== undefined) {
            if (state.successMessage) {
                this.setState({ noFav: true })
                this.emailNotefitacion();
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.getPharmacieByslug(response.data.pharmacySlug)
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ pharmacyData: response.data.pharmacy, spokenLanguages: response.data.pharmacy?.spokenLanguages, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    getLanguages = async () => {
        try {
            let response = await pharmaciesServices.getLanguages()
            this.setState({ languages: response.data?.languages }, () => this.getUser())
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { languages, spokenLanguages, loader, noFav } = this.state
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                        <i className="far fa-check-circle"></i>
                        <p>Votre suggestion a été transmise avec succès.</p>
                    </div>
                }
                <Header />
                <TopOptions title="Langues parlées à la pharmacie ">
                    <button id="printer" className="btn_bleu" onClick={() => this.props.history.push('/account/pharmacy_languages_edit')}>Modifier</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc", marginBottom: "1%" }}>
                            <div className="profil_info_card_header">
                                <h2>Langues parlées à la pharmacie </h2>
                            </div>
                            <div className="profil_info_card_bloc_content">
                                <div className="param_user_guide">
                                    <p>Certaines informations nécessitent une vérification par notre équipe de modération..</p>
                                </div>
                            </div>
                        </div>
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Langues parlées à la pharmacie</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div style={{ display: "flex", flexWrap: 'wrap', width: "100%" }}>
                                        {
                                            languages.map((items) => {
                                                return <div className="user_detail_row" style={{ width: "49%", marginRight: "1%" }}>
                                                    <span style={{ marginBottom: '10px' }}>{items?.name}</span>
                                                    <p className={spokenLanguages.some(item => items.id === item.id) ? "ouiButton" : "nonButton"}>{spokenLanguages.some(item => items.id === item.id) ? 'Oui' : 'Non'}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default PharmacyLanguages;
