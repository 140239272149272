import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from './AboutLandingPage/components/header';
import Section1 from './AboutLandingPage/components/section-1';
import Section2 from './AboutLandingPage/components/section-2';
import Section3 from './AboutLandingPage/components/section-3';
import Section4 from './AboutLandingPage/components/section-4';
import Section5 from './AboutLandingPage/components/section-5';
import Section6 from './AboutLandingPage/components/section-6';
import Section7 from './AboutLandingPage/components/section-7';
import Section8 from './AboutLandingPage/components/section-8';
import Section9 from './AboutLandingPage/components/section-9';
import Footer from './AboutLandingPage/components/footer';
import HeaderLinks from './AboutLandingPage/components/HeaderLinks';
import shape from './AboutLandingPage/assets/shape.svg';
import { ToastContainer } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import './LandingPage.scss';

function LandingPage() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        AOS.init({
            duration : 2500
          })
        // if not a hash link scroll to top
        if (hash === "") {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash]); // do this on route change

    return (
        <>
            <HeaderLinks />
            <div style={{ position: "relative" }}>
                <img className="shape" src={shape} alt="" />
            </div>
            <div className="LandingPage" >
                <Header />
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
                <Section7 />
                <Section8 />
                <Section9 />
                <Footer />
            </div>
            <ToastContainer />
        </>
    );

}

export default LandingPage;