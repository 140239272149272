import React, { Component } from 'react';
import dashpostsalim from '../../images/dashpostsalim.png';
import './Post.css'

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            urlImage: ''
        }
    }

    componentDidMount() {
        this.setState({ urlImage: this.state.data.post_data && this.state.data.post_data.substring(14, this.state.data.post_data.length - 2) });
        
    }

    render() {
        return (
            <div className="Post">
                <div className="Post_header">
                    <img className="User_avatar" src={dashpostsalim} alt="User_avatar" />
                    <div className="Post_infos">
                        <h4 className="User_name-doctor">{this.state.data.poster_app_tenant_name}</h4>
                        <p className="Post_date">{this.state.data.created_on}</p>
                    </div>
                </div>
                <div className="Post_content">
                    {this.state.data.post_data ?
                        <img className="Post_img" src={`https://central.sobrus.com/uploads/ma/posts/${this.state.urlImage}`} alt="Post_img" /> :
                        <img className="Post_img" src={this.state.data.post_url} alt="Post_img" style={{ cursor: "pointer" }} onClick={() => {
                            if (this.state.data.link.length !== 0) {
                                window.open(this.state.data.link, '_blank')
                            }
                        }} />}
                    <p>{this.state.data.content}</p>
                </div>
            </div>
        );
    }
}

export default Post;
