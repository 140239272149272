import React from "react";
import Article from "./Article";
import PageHeader from "./PageHeader";
import '../../LandingPage.scss';

const Conditions = () => {
    return (
        <div className="conditions">
            <PageHeader title="Conditions générales d’utilisation" />
            <div className="container mx-auto">
                <h5 className="h5">Le contenu de cette page est en cours de rédaction.</ h5>
            
                {/* <h4 className="h4">MENTIONS LÉGALES</h4> */}
                {/* <div className="address">
                    <div className="address-right">
                        <h5 className="h5">Société éditrice de ce site Internet :</ h5>
                        <p className="p">USABILIS (RCS Nanterre B 491 325 528)</p>

                        <h5 className="h5">Société éditrice de ce site Internet :</h5>
                        <p className="p">USABILIS (RCS Nanterre B 491 325 528)</p>
                    </div>
                    <div className="address-left">
                        <h5 className="h5">Adresse du siège social :</h5>
                        <p className="p">
                            USABILIS <br />
                            18, rue de Saisset <br />
                            92120 Montrouge
                        </p>
                        <h5 className="h5">Directeur de la publication et responsable du site :</h5>
                        <p className="p">USABILIS</p>
                        <h5 className="h5">Création et gestion du site :</h5>
                        <p className="p">USABILIS</p>
                        <h5 className="h5">Hébergement :</h5>
                        <p className="p">Online.net</p>
                    </div>
                </div> */}
            </div>
            {/* <Article
                title="Article 1 : Collecte et utilisation des données personnelles"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="Article 2 : Responsabilité"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
            />
            <Article
                title="Article 1 : Collecte et utilisation des données personnelles"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="Article 1 : Collecte et utilisation des données personnelles"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
            />
            <Article
                title="Article 1 : Collecte et utilisation des données personnelles"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
                hasBG={true}
            />
            <Article
                title="Article 1 : Collecte et utilisation des données personnelles"
                body={
                    <>
                        <p className="p">
                            Pour sa partie publique, le site est consultable par l’utilisateur sans
                            donner son identification ou la moindre information le concernant.
                        </p>
                        <p className="p">
                            Les données collectées par l’intermédiaire du formulaire de contact sont
                            nécessaires pour répondre aux demandes de renseignements effectuées par
                            l’utilisateur.
                        </p>
                        <p className="p">
                            USABILIS ne vend pas, ne loue pas ou ne cède pas à bail ses listes de
                            clients à des tiers.
                        </p>
                        <p className="p">
                            Vous disposez d’un droit d’accès, de modification, de rectification et
                            de suppression des données vous concernant (loi “Informatique et
                            Libertés” du 6 janvier 1978 modifiée). Pour toute demande, adressez-vous
                            à :<br />
                            USABILIS
                            <br />
                            18, rue de Saisset
                            <br />
                            92120 Montrouge
                            <br />
                            ou utilisez le formulaire de contact.
                        </p>
                    </>
                }
            /> */}
        </div>
    );
};

export default Conditions;
