import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import Loader from '../../Helpers/Loader';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import pharmaciesServices from '../../services/pharmacies.services';
import userService from '../../services/user.service';

class DisplayParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacy: [],
            noFav: false,
            loader: true
        }
    }

    componentDidMount() {
        document.title = "Rapports - Sobrus Pharma";
        this.getUser()
        const state = this.props.location.state;
        if (state !== undefined) {
            if (state.successMessage) {
                this.setState({ noFav: true })
                this.emailNotefitacion();
            }
        }
    }

    emailNotefitacion() {
        setTimeout(() => {
            this.setState({ noFav: false })
        }, 2000);
    }

    getUser = async () => {
        try {
            let response = await userService.getUser()
            this.getPharmacieByslug(response.data.pharmacySlug)
        } catch (error) {
            console.log(error);
        }
    }

    getPharmacieByslug = async (slug) => {
        try {
            let response = await pharmaciesServices.getPharmacieByslug(slug)
            this.setState({ pharmacy: response.data.pharmacy, loader: false })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { pharmacy, loader, noFav } = this.state
        return (
            <div style={{ minWidth: "960px" }}>
                {loader && <Loader />}
                {
                    noFav && <div className="sign_up_notification" >
                        <i className="far fa-check-circle"></i>
                        <p>Vos paramètres d'affichage ont bien été modifiés.</p>
                    </div>
                }
                <Header />
                <TopOptions title="Paramètres d'affichage">
                    <button id="printer" className="btn_bleu" onClick={() => this.props.history.push('/account/display_params_edit')}>Modifier</button>
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        {/* <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc", marginBottom: "1%" }}>
                            <div className="profil_info_card_header">
                                <h2>Paramètres d'affichage</h2>
                            </div>
                            <div className="profil_info_card_bloc_content">
                                <div className="param_user_guide">
                                    <p>Certaines informations nécessitent une vérification par notre équipe de modération..</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="ParametresOrdonnanceEdit">
                            <div className="bloc_header">
                                <h2>Paramètres d'affichage</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="ParametresGrid">
                                    <div style={{ display: "flex", flexWrap: 'wrap', width: "100%" }}>
                                        <div className="user_detail_row" style={{ width: "49%", marginRight: "1%" }}>
                                            <span style={{ marginBottom: '10px' }}>Nom du pharmacien</span>
                                            <p className={pharmacy?.showPharmacistName ? "ouiButton" : "nonButton"}>{pharmacy?.showPharmacistName ? 'Oui' : 'Non'}</p>
                                        </div>
                                        <div className="user_detail_row" style={{ width: "49%", marginRight: "1%" }}>
                                            <span style={{ marginBottom: '10px' }}>Diplôme</span>
                                            <p className={pharmacy?.showPharmacistDegree ? "ouiButton" : "nonButton"}>{pharmacy?.showPharmacistDegree ? 'Oui' : 'Non'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactButton />
            </div>
        );
    }
}

export default DisplayParams;
