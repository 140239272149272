import React, { Component } from 'react';
import './ContactButton.css'
import Dr_Salim_Headset from '../../images/Dr_Salim_Headset.png'

class ContactButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPop_up: false,
        };
    }
    render() {
        const { showPop_up } = this.state;
        return (
            <div className="ContactButton">
                {
                    showPop_up && (
                        <div className="Pop_up_container">
                            <div className="HeaderContainer">
                                <p>Comment pouvons-nous vous aider ?</p>
                            </div>
                            <div className="ContentContainer">
                                <div className="CircleIcon">
                                    <img src={Dr_Salim_Headset} alt="Dr_Salim_Headset" />
                                </div>
                                <p>Pour toute question appelez-nous:</p>
                                <span>05 30 500 500</span>
                            </div>
                        </div>
                    )
                }
                <ul>
                    <li>
                        <a className={showPop_up ? "Contact_btn" : "Contact_btn_inshow"} onClick={() => this.setState({ showPop_up: showPop_up ? false : true })}>
                            {
                                showPop_up ? (<span className="Icon_container">
                                    <svg width={20} height={20} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#04beb1" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                                </span>) : (<i className="Contact_icon" />)
                            }
                            <span className={showPop_up ? "Contact_btn_text" : "Contact_btn_text_inshow"}>Contacter Sobrus</span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default ContactButton;
