import React, { Component } from 'react';
import ContactButton from '../../Helpers/ContactButton/ContactButton';
import Header from '../../Helpers/Header/Header';
import InfoCard from '../../Helpers/InfoCard/InfoCard';
import SideMenu from '../../Helpers/SideMenu/SideMenu';
import TopOptions from '../../Helpers/TopOptions/TopOptions';
import connectionService from '../../services/connection.service';
import moment from 'moment'
import './AccountLogins.css';

const thead = [
    { property: "user", label: "Utilisateur", type: "string" },
    { property: "createdAt", label: "Heure", type: "string" },
    { property: "ipAddress", label: "IP", type: "integer" },
    { property: "machineType", label: "Type d'appareil", type: "integer" },
]

class AccountLogins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            elementsPerPage: 10,
            ConnectionHistory: null,
            is_loaded: false,
            totalItems: 0,
            data: null,
            user: null
        }
    }

    componentDidMount() {
        document.title = "Ordonnances - Sobrus Pharma";
        this.getConnectionHistory()
    }

    prepareData = (data) => {
        let ConnectionHistory = []

        data.forEach(el => {
            let HistoryCon = {
                user: this.state.user,
                createdAt: moment(el.createdAt).format("YYYY-MM-DD à HH:mm:ss"),
                ipAddress: el ? el.ipAddress : " ",
                machineType: el ? el.machineType : ""
            }
            ConnectionHistory.push(HistoryCon)
        });
        this.setState({ ConnectionHistory })
    }

    getConnectionHistory = async () => {
        const { currentPage, elementsPerPage } = this.state;
        try {
            let response = await connectionService.getAllConnectionHistory(currentPage, elementsPerPage)
            this.setState({ is_loaded: true, data: response.data.connections, user: response.data.userName }, () => this.prepareData(this.state.data))
        } catch (error) {
            console.log(error);
        }
    }


    render() {
        const { is_loaded, ConnectionHistory } = this.state
        return (
            <>
                <Header />
                <TopOptions title="Compte">
                </TopOptions>
                <div className="info_profile_and_sub_menu">
                    <SideMenu />
                    <div style={{ width: "74%" }}>
                        <div className="ParametresInfosEdit" style={{ boxShadow: "0 1px 3px 0 #bcbcbc" }}>
                            <div className="profil_info_card_header">
                                <h2>Historique des connexions</h2>
                            </div>
                            <div className="profil_info_card_bloc_content">
                                <div className="param_user_guide">
                                    <p>Cette page contient la liste des connexions d'utilisateurs.</p>
                                </div>
                            </div>
                        </div>
                        <InfoCard
                            thead={thead}
                            tbody={{
                                dataIsLoaded: is_loaded,
                                data: ConnectionHistory ? ConnectionHistory : [],
                                action: this.odronnanceOfPatient
                            }}
                            {...this.props}>
                        </InfoCard>
                    </div>
                </div>
                <ContactButton />
            </>
        );
    }
}

export default AccountLogins;
