import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./AboutLandingPage/components/header";
import Footer from './AboutLandingPage/components/footer';
import HeaderLinks from './AboutLandingPage/components/HeaderLinks';
import Politiques from "./AboutLandingPage/components/Politiques";
import './LandingPage.scss';

function PolitiquesPage() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        // if not a hash link scroll to top
        if (hash === "") {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace("#", "");
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash]); // do this on route change

    return (
        <div className="LandingPage">
            <HeaderLinks />
            <Header />
            <Politiques />
            <Footer />
        </div>
    );

}

export default PolitiquesPage;